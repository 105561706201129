import React, { Component } from "react";
import SellerCategoryContext from "../context/SellerCategoryContext";
import SellerCategoryMain from "../components/SellerCategory/SellerCategoryMain";
import SellerPrmContext from "../context/SellerPrmContext";

export default class SellerCategory extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => (
          <SellerCategoryContext.Consumer>
            {(sellerCategory) => (
              <SellerCategoryMain sellerCategory={sellerCategory} sellerPrm={sellerPrm} />
            )}
          </SellerCategoryContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}
