import React, { Component } from "react";
import {
  MDBCard,
  MDBCol,
  MDBRow,
  MDBContainer,
  MDBCardImage,
  MDBCardBody,
  MDBCardFooter,
} from "mdbreact";
import style from "./Product.module.css";
import { Link, withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";

class ProductView extends Component {
  render() {
    const p = this.props.product;
    const type = this.props.type;
    return (
      <>
        {p === null
          ? " "
          : p.map((data, index) => {
              const detail_flag =
                type === "all" ? data.price_product_single : data.price;
              if (detail_flag && (type === "all" ? index < 4 : "true")) {
                return (
                  <MDBCol
                    key={data.id}
                    className={style.container}
                    size={type === "all" ? "3" : "2"}
                  >
                    {/* <Link to={`product_detail/${data.id}`} > */}
                    <Button
                      onClick={() => {
                        this.props.history.push(`/product_detail/${data.id}`);
                      }}
                    >
                      <MDBCard cascade narrow ecommerce>
                        <MDBContainer>
                          <MDBCardImage
                            src={
                              type === "all"
                                ? data.thumbnail.link
                                  ? data.thumbnail.link
                                  : ""
                                : data.img
                                ? data.img
                                : ""
                            }
                            top
                            // overlay="white-slight"
                          />
                          <MDBCardBody cascade className={style.cardBody}>
                            <div className={style.title}>{data.name}</div>
                            <MDBCardFooter className={style.cardFooter}>
                              <MDBCol className={style.price}>
                                {detail_flag
                                  ? new Intl.NumberFormat("id-ID", {
                                      style: "currency",
                                      currency: "IDR",
                                    }).format(
                                      type === "all"
                                        ? data.price_product_single.price
                                        : data.price.price
                                    )
                                  : ""}
                              </MDBCol>
                            </MDBCardFooter>
                          </MDBCardBody>
                        </MDBContainer>
                      </MDBCard>
                    </Button>
                    {/* </Link> */}
                  </MDBCol>
                );
              }
            })}
      </>
    );
  }
}

export default withRouter(ProductView);
