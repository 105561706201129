import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  // MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBadge,
  MDBJumbotron,
  MDBFooter,
  MDBCardImage,
} from "mdbreact";
import Banner from "../../components/HeaderAfterLogin/Banner";

// import "../css/Home.css";
// import styles from "../css/Home.module.css";

class Home extends React.Component {
  render() {
    const containerStyle = {
      marginTop: "8rem",
    };

    return (
      <div id="single">
        {/* <Banner /> */}

        <MDBContainer /* style={containerStyle} */>
          <MDBRow>
            <MDBCol>
              <Banner />
            </MDBCol>
          </MDBRow>

          <hr className="my-5" />

          <MDBJumbotron className="p-5 text-center text-md-left author-box">
            <h3 className="text-center my-5 text-center h3">Produk Baru</h3>
            <MDBRow className="mt-1 mb-1">
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/12.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Shirt</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Denim shirt{" "}
                        <MDBBadge pill color="danger">
                          NEW
                        </MDBBadge>
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>120$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/13.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Sport wear</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Sweatshirt
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>139$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/14.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Sport wear</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Grey blouse{" "}
                        <MDBBadge pill color="primary">
                          BEST
                        </MDBBadge>
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>99$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/15.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Outwear</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Black jacket
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>219$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
            </MDBRow>

            <MDBRow className="mt-1 mb-1">
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/12.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Shirt</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Denim shirt{" "}
                        <MDBBadge pill color="danger">
                          NEW
                        </MDBBadge>
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>120$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/13.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Sport wear</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Sweatshirt
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>139$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/14.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Sport wear</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Grey blouse{" "}
                        <MDBBadge pill color="primary">
                          BEST
                        </MDBBadge>
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>99$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
              <MDBCol lg="3" md="6" className="mb-lg-0 mb-4">
                {/* <MDBCard className="align-items-center"> */}
                <MDBCardImage
                  src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Vertical/15.jpg"
                  top
                  alt="sample photo"
                  overlay="white-slight"
                />
                <MDBCardBody className="text-center">
                  <a href="#!" className="grey-text">
                    <h5>Outwear</h5>
                  </a>
                  <h5>
                    <strong>
                      <a href="#!" className="dark-grey-text">
                        Black jacket
                      </a>
                    </strong>
                  </h5>
                  <h4 className="font-weight-bold blue-text">
                    <strong>219$</strong>
                  </h4>
                </MDBCardBody>
                {/* </MDBCard> */}
              </MDBCol>
            </MDBRow>
          </MDBJumbotron>
        </MDBContainer>

        <MDBFooter className="pt-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <div className="text-center d-flex justify-content-center my-4">
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="facebook"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="twitter"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="google-plus"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="linkedin"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="instagram"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="pinterest"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      </div>
    );
  }
}

export default Home;
