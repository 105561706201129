import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {PrivateRoute} from "../utils/PrivateRoute";
import Home from "./pages/Home";
import Cart from "../pages/Cart"
import SellerLocation from "../pages/SellerLocation";
import SellerCategory from "../pages/SellerCategory";
import SellerProducts from "../pages/SellerProducts";
import SellerProductsForm from "../pages/SellerProductsForm";
import ProfileUser from "../pages/ProfileUser";
import ProfileSeller from "../pages/ProfileSeller";
import ProductDetail from "../pages/ProductDetail"
import Login from "./pages/Login";
import RegisterUser from "../pages/RegisterUser"
import Shipment from "../pages/Shipment"
import AboutSetting from "../pages/AboutSetting"
import About from "../pages/About";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={RegisterUser} />
        <Route path="/category/:page?" exact component={Home} />
        <Route path="/product_detail/:id" exact component={ProductDetail}/>
        <PrivateRoute path="/cart" exact component={Cart}/>
        <PrivateRoute path="/cart/shipment" exact component={Shipment}/>
        <PrivateRoute path="/admin/sellerlocation" exact component={SellerLocation} />
        <Route path="/admin/sellercategory" exact component={SellerCategory} />
        <Route path="/admin/sellerproducts" exact component={SellerProducts} />
        <PrivateRoute path="/admin/sellerproducts/add-product" exact component={SellerProductsForm} />
        <PrivateRoute path="/admin/sellerproducts/edit-product/:id" exact component={SellerProductsForm} />
        <PrivateRoute path='/admin/sellerprofile' exact component={ProfileSeller} />
        <PrivateRoute path='/admin/storeprofile' exact component={AboutSetting} />
        <PrivateRoute path='/profile' exact component={ProfileUser} />
        <PrivateRoute path='/profile/location' exact component={ProfileUser}/>
        <PrivateRoute path='/profile/bank_account' exact component={ProfileUser}/>
        <PrivateRoute path='/about' exact component={About} /> 
      </Switch>
    );
  }
}

export default Routes;
