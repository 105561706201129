import React, { Component } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBContainer } from "mdbreact";
import SectionContainer from "../sectionContainer";
import ProductView from "./ProductView";
import { Link } from "react-router-dom";

export default class ProductCategoryView extends Component {
  render() {
    const p = this.props.producthome;
    const jml_item = p.product !== undefined ? p.product.length : "";
    return (
      <>
        {this.props.selectedTab === 0 ? (
          p.category.map((data) => {
            if (data.product.length > 0) {
              return (
                <SectionContainer key={data.id} header={data.name}>
                  <MDBContainer>
                    <MDBRow>
                      <ProductView type="all" product={data.product} />
                    </MDBRow>
                  </MDBContainer>
                  <Link to={"/category/" + data.id}>
                    <MDBBtn className="w-100" color="deep-purple">
                      Lihat Lebih Banyak
                    </MDBBtn>
                  </Link>
                </SectionContainer>
              );
            } else {
              return "";
            }
          })
        ) : p.product !== undefined ? (
          <SectionContainer>
            <MDBContainer>
              <MDBRow>
                <ProductView type="product" product={p.product} />
              </MDBRow>
            </MDBContainer>
            {jml_item !== p.totalItem ? (
              <MDBBtn
                className="w-100"
                color="deep-purple"
                onClick={() => this.props.loadMoreProduct()}
              >
                Lihat Lebih Banyak
              </MDBBtn>
            ) : (
              ""
            )}
          </SectionContainer>
        ) : (
          ""
        )}
      </>
    );
  }
}
