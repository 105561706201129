import React, { Component } from "react";
import SellerPrmContext from "../../context/SellerPrmContext";
import { MDBCard, MDBView } from "mdbreact";

export default class Banner extends Component {
  static contextType = SellerPrmContext;

  render() {
    return (
      <div>
        <MDBCard>
          <MDBView>
            <img
              src={this.context.subDomainData.banner}
              style={{
                width: "100%",
                // minHeight: "100vh",
                minHeight: "auto",
                // backgroundSize: "cover",
                // backgroundRepeat: "no-repeat",
                // backgroundPosition: "center center",
              }}
              alt="Banner Template"
            />
          </MDBView>
        </MDBCard>
      </div>
    );
  }
}
