import React, { Component } from "react";
import { Tabs, Tab, AppBar } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import ProductCategoryView from "./ProductCategoryView";

class ProductCategoryTabView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      product_page: 1,
    };
    this.sellerId = this.props.sellerprm.subDomainData.id;
  }

  componentDidMount() {
    let page =
      this.props.match.params.page === undefined
        ? 0
        : this.props.match.params.page;
    this.props.producthome.getProductCategory(this.sellerId, page);
    this.setState({ selectedTab: page });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let page =
        this.props.match.params.page === undefined
          ? 0
          : this.props.match.params.page;
      if (page !== 0) {
        this.props.producthome.getProductCategory(this.sellerId, page);
      }
      this.setState({ selectedTab: page, product_page: 1 });
    }
  }

  handleChange = (event, newValue) => {
    newValue = newValue === undefined ? 0 : newValue;
    this.setState({ selectedTab: newValue });
  };

  loadMoreProduct = () => {
    this.setState({ product_page: this.state.product_page + 1 }, () => {
      this.props.producthome.getProductCategory(
        this.sellerId,
        this.state.selectedTab,
        this.state.product_page
      );
    });
  };

  render() {
    const p = this.props.producthome;
    return (
      <>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.selectedTab}
            onChange={this.handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab key="0" label="Semua" component={Link} to="/" value={0} />
            {p.category.map((data, index) => {
              if (data.product.length > 0) {
                return (
                  <Tab
                    key={data.id}
                    // icon={<img src={data.img} style={{width:'40px',height:'40px'}}/>}
                    label={data.name}
                    component={Link}
                    to={"/category/" + data.id}
                    value={data.id}
                  />
                );
              } else {
                return "";
              }
            })}
          </Tabs>
        </AppBar>

        <ProductCategoryView
          {...this.props}
          sellerId={this.sellerId}
          selectedTab={this.state.selectedTab}
          loadMoreProduct={this.loadMoreProduct}
        />
      </>
    );
  }
}

export default withRouter(ProductCategoryTabView);
