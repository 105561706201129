import React, { Component } from "react";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import SellerPrmContext from "../../context/SellerPrmContext";
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBRow,
  MDBCol,
  MDBNavLink,
} from "mdbreact";
import styles from "./HeaderProduct.module.css";

export default class HeaderProducts extends Component {
  _isMounted = false;
  static contextType = SellerPrmContext;
  state = {
    data: ""
  }
  componentDidMount() {
    this._isMounted = true;
    if (this.context.subDomainData) {
      this.listSellerCategoryActive();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  listSellerCategoryActive = () => {
    const { id } = this.context.subDomainData;
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_category/${id}?active=1`, config)
      .then((res) => {
        if (res.data.data) {
          let listData = res.data.data.category.map((list, idx) => ({
            //react-grid-dropdown
            label: list.name,
            backgroundImage: `url(${list.img})`,
            img: list.img,
            index: idx,
            //select
            text: list.name,
            value: list.id.toString(),
          }));

          if (this._isMounted) {
            this.setState({
              data: listData,
            });
          }
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        // if (error.response) {
        //   /*
        //    * The request was made and the server responded with a
        //    * status code that falls out of the range of 2xx
        //    */
        //   console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
        //   if (error.response) {
        //     let errmessage = "";
        //     let keys = Object.keys(error.response.data.errors);
        //     for (let i = 0; i < keys.length; i++) {
        //       errmessage +=
        //         keys[i] +
        //         " " +
        //         error.response.data.errors[keys[i]][0] +
        //         "<br/>";
        //     }
        //     Swal.fire({
        //       icon: "error",
        //       html: errmessage,
        //     });
        //   }
        // } else if (error.request) {
        //   /*
        //    * The request was made but no response was received, `error.request`
        //    * is an instance of XMLHttpRequest in the browser and an instance
        //    * of http.ClientRequest in Node.js
        //    */
        //   console.log(error.request);
        // } else {
        //   // Something happened in setting up the request and triggered an Error
        //   console.log("Error", error.message);
        // }
        // console.log(error.config);
      });
  };

  render() {
    return (
      <>
        <MDBDropdown>
          <MDBDropdownToggle nav caret color="secondary">
            Products
          </MDBDropdownToggle>
          <MDBDropdownMenu color="secondary" style={{ width: "300px" }}>
            {/* Category List */}
            <MDBRow>
               {this.state.data && this.state.data.map((data, idx) => (
                <MDBCol size="6" className={styles.containerCategory} key={idx}>
                  <MDBNavLink className={styles.containerCategoryLink}  to="#">
                    <MDBCol size="12" className={styles.containerImage}><img className={styles.images} src={data.img} alt={data.label + idx} /></MDBCol>
                    <MDBCol size="12" className={styles.containerLabel}>{data.label}</MDBCol>
                  </MDBNavLink>
                </MDBCol>
              ))}
              {/* {this.renderRow()} */}
            </MDBRow>
          </MDBDropdownMenu>
        </MDBDropdown>
      </>
    );
  }
}
