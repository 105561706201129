import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBBtn,
} from "mdbreact";
import { Link } from "react-router-dom";
import satuTokoLogo from "../../assets/toko1_ORIGINAL.askdlaskda.png";
import styles from "./LoginView.module.css";
import { Text } from "../../context/Language";

export class LoginView extends Component {
  render() {
    return (
      <div className={styles.outerContainer}>
        <div className={[styles.container].join(" ")}>
          <MDBCard className={styles.card}>
            {/* <div className={styles.title}>
              LOGIN
            </div> */}
            <div className={styles.logoContainer}>
              <img src={satuTokoLogo} className={styles.logo} alt="satu-toko" />
            </div>
            <form
              className="needs-validation"
              onSubmit={this.props.submitHandler}
              noValidate
            >
              <MDBContainer className={styles.innerContainer}>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.email}
                      onChange={this.props.changeHandler}
                      type="email"
                      className="form-control"
                      name="email"
                      label={<Text pid="loginPage" tid="emailInput" /> }
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.password}
                      onChange={this.props.changeHandler}
                      type="password"
                      className="form-control"
                      name="password"
                      label={<Text pid="loginPage" tid="passInput" />}
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol className={styles.checkRememberMe}>
                    <MDBInput
                      label={<Text pid="loginPage" tid="rememberMeCheck" />}
                      onChange={this.props.checkRememberMeHandler}
                      type="checkbox"
                      id="rememberMe"
                      filled
                    />
                  </MDBCol>
                  <MDBCol className={styles.forgotPassword}>
                    <Link to="#" onClick={this.props.forgotPassword}>{<Text pid="loginPage" tid="forgotPass" />}</Link>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <MDBRow className={styles.submitContainer}>
                <MDBCol>
                  <MDBBtn className="w-100" type="submit">
                    {<Text pid="loginPage" tid="login" />}
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className={styles.toRegister}>
                  <Link to="/register">{<Text pid="loginPage" tid="toRegister" />}</Link>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCard>
        </div>
      </div>
    );
  }
}

export default LoginView;
