import React, { Component } from "react";
import {
  MDBContainer,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBIcon,
} from "mdbreact";
import API from "../../utils/API";
import Cookies from "js-cookie";
import { Text } from "../../context/Language";
import SectionContainer from "../sectionContainer";
import ProfileViewUser from "../ProfileView/ProfileViewUser";
import CPFromModal from "../ChangePasswordView/CPFormModal";
import Swal from "sweetalert2";
import UserLocation from "../../pages/UserLocation";
import { withRouter } from "react-router-dom";
import UserLocationProvider from "../../context/UserLocationProvider";
import BankAccount from "../../pages/BankAccount";

class ProfileUser extends Component {
  constructor(props) {
    super(props);
    let token = Cookies.get("access_token");
    this.state = {
      data: {
        id: "",
        name: "",
        email: "",
        old_password: "",
        new_password: "",
        conf_password: "",
      },
      error: {
        old_password: "",
        new_password: "",
        conf_password: "",
      },
      activeItemOuterTabs: "1",
      modal1: false,
      isEdit: true,
    };

    this.config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
  }

  componentDidMount() {
    let page = this.props.location.pathname;
    let id = this.props.sellerprm.subDomainData.id;
    this.getProfile(id);
    this.setState({
      activeItemOuterTabs: this.setPage(page),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let page = this.props.location.pathname;
      
      this.setState({
        activeItemOuterTabs: this.setPage(page),
      });
    }
  }

  setPage = (halaman) =>{
    switch(halaman){
      case "/profile" : halaman = "1"; break;
      case "/profile/location" : halaman = "2"; break;
      case "/profile/bank_account" : halaman = "3"; break;
      default : halaman = "1"; break;
    }
    return halaman;
  }

  getProfile = (id) => {
    API.get("auth/user/" + id, this.config)
      .then((res) => {
        const data_user = res.data.data.user;
        const user = { ...this.state.data };
        user.id = data_user.id;
        user.name = data_user.name;
        user.email = data_user.email;
        this.setState({ data: user });
      })
      .catch((error) => {});
  };

  changeHandler = (event) => {
    const { id, name, value } = event.target;
    if (id !== "") {
      // console.log(id,name, value)
      const data = { ...this.state[id] };
      data[name] = value;
      this.setState({ data: data });
    } else {
      this.setState({ name: value });
    }
  };

  changePasswordEvent = (sent_data) => {
    API.post("password/reset_password", sent_data, this.config)
      .then((res) => {
        Swal.fire({
          icon: "success",
          html: "Password has been changed",
        }).then((res) => {
          this.popupModal("1");
          const data = { ...this.state.data };
          data.new_password = "";
          data.old_password = "";
          data.conf_password = "";
          this.setState({ data: data });
        });
      })
      .catch((error) => {
        Swal.disableLoading();
        // if (error.response) {
        //     /*
        //     * The request was made and the server responded with a
        //     * status code that falls out of the range of 2xx
        //     */
        //     console.log(error.response.data);
        //     console.log(error.response.status);
        //     console.log(error.response.headers);
        //     if (error.response) {
        //     let errmessage = "";
        //     let keys = Object.keys(error.response.data.errors);
        //     for (let i = 0; i < keys.length; i++) {
        //         errmessage +=
        //         keys[i] + " " + error.response.data.errors[keys[i]][0] + "<br/>";
        //     }
        //     Swal.fire({
        //         icon: "error",
        //         html: errmessage,
        //     });
        //     }
        // } else if (error.request) {
        //     /*
        //     * The request was made but no response was received, `error.request`
        //     * is an instance of XMLHttpRequest in the browser and an instance
        //     * of http.ClientRequest in Node.js
        //     */
        //     console.log(error.request);
        // } else {
        //     // Something happened in setting up the request and triggered an Error
        //     console.log("Error", error.message);
        // }
        // console.log(error.config);
      });
  };

  changePassword = (event) => {
    // console.log(event.target)
    let { old_password, new_password, conf_password } = this.state.data;
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const sent_data = new FormData();
    sent_data.append("old_password", old_password);
    sent_data.append("password", new_password);
    sent_data.append("password_confirmation", conf_password);
    sent_data.append("url", "localhost:3000/profile");
    const passregex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    if (old_password !== "" && new_password !== "" && conf_password !== "") {
      if (old_password.length >= 6) {
        if (passregex.test(new_password)) {
          if (new_password !== old_password) {
            if (new_password === conf_password) {
              this.changePasswordEvent(sent_data);
            } else {
              Swal.fire({
                icon: "error",
                html: "New Password and  Confirmation Password is not match",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              html: "New Password cannot be same as Old Password",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            html:
              "New Password must be 6 character or more and " +
              "contain lowercase and uppercase alphabet, " +
              "and numeric character",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          html: "Wrong old password",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        html: "Please fill all data",
      });
    }
  };

  updateUserProfileEvent = (sent_data) => {
    API.post("/auth/update_user", sent_data, this.config)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data berhasil terupdate",
        }).then(() => {
          this.isEditChange();
        });
      })
      .catch((error) => {
        Swal.disableLoading();
      });
  };

  updateUserProfile = (event) => {
    const { data } = this.state;

    Swal.fire({
      title: "Loading",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    event.preventDefault();
    event.target.className += " was-validated";

    const sent_data = new FormData();
    sent_data.append("id", data.id);
    sent_data.append("name", data.name);
    sent_data.append("email", data.email);
    const emailregex = new RegExp("^.*@.*\\..*");
    if (data.email !== "" && data.name !== "") {
      if (emailregex.test(data.email)) {
        console.log("masuk");
        this.updateUserProfileEvent(sent_data);
      } else {
        Swal.fire({
          icon: "error",
          title: "Sorry...",
          text: "Silahkan masukan email yang benar",
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Silahkan lengkapi data anda",
      }).then(() => {
        let id = this.props.sellerprm.subDomainData.id;
        this.getProfile(id);
      });
    }
  };

  isEditChange = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  //modal function
  popupModal = async (no) => {
    let modal = "modal" + no;
    if (no == 1) {
      let data = { ...this.state.data };
      data.old_password = "";
      data.new_password = "";
      data.conf_password = "";
      this.setState({ data: data });
    }
    this.setState({ [modal]: !this.state[modal] });
  };

  //tab function
  toggleOuterTabs = (tab) => (e) => {
    const { activeItemOuterTabs } = this.state;
    if (activeItemOuterTabs !== tab) {
      this.setState({
        activeItemOuterTabs: tab,
      });
    }
  };

  render() {
    const { activeItemOuterTabs } = this.state;
    const sellerId = this.props.sellerprm.subDomainData.id;
    return (
      <MDBContainer style={{ paddingTop: "4rem", minWidth: "1200px" }}>
        <SectionContainer header={<Text pid="profileUser" tid="title" />}>
          <MDBNav tabs className="nav-justified" color="primary">
            <MDBNavItem>
              <MDBNavLink
                link
                to="/profile"
                active={activeItemOuterTabs === "1"}
                onClick={this.toggleOuterTabs("1")}
                role="tab"
              >
                <MDBIcon icon="user" /> Profile
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="/profile/location"
                active={activeItemOuterTabs === "2"}
                onClick={this.toggleOuterTabs("2")}
                role="tab"
              >
                <MDBIcon icon="map-marked" /> User Location
              </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink
                link
                to="/profile/bank_account"
                active={activeItemOuterTabs === "3"}
                onClick={this.toggleOuterTabs("3")}
                role="tab"
              >
                <MDBIcon icon="money-check" /> Rekening Bank 
              </MDBNavLink>
            </MDBNavItem>
          </MDBNav>
          <MDBTabContent className="card mb-5" activeItem={activeItemOuterTabs}>
            <MDBTabPane tabId="1" role="tabpanel">
              <CPFromModal
                data={this.state.data}
                openState={this.state.modal1}
                popupModal={this.popupModal}
                changePassword={this.changePassword}
                changeHandler={this.changeHandler}
              />
              <ProfileViewUser
                data={this.state.data}
                isEdit={this.state.isEdit}
                popupModal={this.popupModal}
                isEditChange={this.isEditChange}
                changeHandler={this.changeHandler}
                updateUserProfile={this.updateUserProfile}
              />
            </MDBTabPane>
            <MDBTabPane tabId="2" role="tabpanel" >
              <UserLocationProvider>
                <UserLocation />
              </UserLocationProvider>
            </MDBTabPane>
            <MDBTabPane tabId="3" role="tabpanel" >
              <BankAccount
                sellerId ={sellerId}
              />
            </MDBTabPane>
          </MDBTabContent>
        </SectionContainer>
      </MDBContainer>
    );
  }
}

export default withRouter(ProfileUser);
