import React, { createContext, Component } from "react";
//import for provider
import API from "../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

//Context
export const ProductHomeContext = createContext();

//Provider
class ProductHomeProvider extends Component {
  state = {
    category: undefined,
    product: undefined,
    product_detail: undefined,
    productDetailPhoto: undefined,
    totalItem: undefined,
  };

  //handler
  setSelectedProduct = (product) => {
    this.setState({ selected_product: product });
  };

  //get data
  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getProductAllCategory = (sellerId) => {
    API.get("home/" + sellerId, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        // console.log(res.data.data.category)
        if (res) {
          let tempcat = res.data.data.category;
          tempcat = tempcat.map((data) => ({
            id: data.id,
            name: data.name,
            img: data.img,
            is_active: data.is_active,
            product: data.product,
          }));
          this.setState({ category: tempcat });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProductCategory = async (sellerId, category_id, Prmpage = 1, Prmlimit) => {
    const page = Prmpage ? "?page=" + Prmpage : "?page=1";
    const limit = Prmlimit ? "&limit=" + Prmlimit : "";
    API.get(
      "home/" + sellerId + "/cat/" + category_id + page + limit,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (res.data.data) {
          let product_temp = res.data.data.product.data.map((list) => ({
            id: list.id,
            name: list.name,
            categoryId: list.category_id,
            img: list.thumbnail.link,
            price: list.price_product_single,
          }));
          this.setState((prevState) => ({
            product:
              Prmpage !== 1
                ? [...prevState.product, ...product_temp]
                : product_temp,
            totalItem: res.data.data.product.total,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getProductDetail = (sellerId, product_id) => {
    API.get(`home/${sellerId}/${product_id}`)
      .then((res) => {
        this.setState({ product_detail: res.data.data.product[0] });
        this.setProductDetailPhoto();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  setProductDetailPhoto = () => {
    const { product_detail } = this.state;
    let allPhotoTemp;
    let productPhotoTemp;
    let productDetailPhotoTemp;

    productPhotoTemp = product_detail.photo_product.map((item) => ({
      ...item,
      detail_photo: false,
    }));

    productDetailPhotoTemp = product_detail.dtl_product_active.map((item) => ({
      ...item.photo_product[0],
      detail_photo: true,
    }));

    allPhotoTemp = [...productPhotoTemp, ...productDetailPhotoTemp];

    allPhotoTemp = allPhotoTemp.map((item, index) => ({
      ...item,
      itemId: index + 1,
    }));

    this.setState({
      productDetailPhoto: allPhotoTemp,
    });
  };

  //other
  addToChartFromPage = (sellerId, product_id, quantity, note = "") => {
    const data = new FormData();
    data.append("product_id", product_id);
    data.append("qty", quantity);
    data.append("note", note);

    API.post(`cart/${sellerId}`, data, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        Swal.fire({
          icon: "success",
          html: "Item successfully added to cart",
        }).then(() => {
          this.props.history.push("/cart");
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <ProductHomeContext.Provider
        value={{
          ...this.state,
          getProductAllCategory: this.getProductAllCategory,
          getProductCategory: this.getProductCategory,
          getProductDetail: this.getProductDetail,
          setSelectedProduct: this.setSelectedProduct,
          addToChartFromPage: this.addToChartFromPage,
        }}
      >
        {this.props.children}
      </ProductHomeContext.Provider>
    );
  }
}

export default withRouter(ProductHomeProvider);
