import React, { Component } from "react";

import SellerLocationMain from "../components/SellerLocation/SellerLocationMain";
import SellerLocationContext from "../context/SellerLocationContext";
import SellerPrmContext from "../context/SellerPrmContext";

export default class SellerLocation extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerPrm) => (
          <SellerLocationContext.Consumer>
            {(sellerLocation) => (
              <SellerLocationMain
                sellerLocation={sellerLocation}
                sellerPrm={sellerPrm}
              />
            )}
          </SellerLocationContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}
