import React from "react";
import { Route, Switch } from "react-router-dom";

import Register from "./pages/Register";
import Login from "./pages/Login";
import Landing from "./pages/Landing";
import ChangePasswordToken from "./pages/ChangePasswordToken";
import Activation from "./pages/Activation";
import Search from "./pages/Search"

class MainRoutes extends React.Component {
    render() {
        return(
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/login" component={Login}/>
                <Route exact path="/activation/:token" component={Activation}/>
                <Route exact path="/change_password_token/:token?" component={ChangePasswordToken}/>
                <Route exact path="/search/:text?" component={Search}/>
            </Switch>
        )
    }
}

export default MainRoutes;