import React, { Component } from "react";
import {
  MDBCard,
  MDBRow,
  MDBCardImage,
  MDBCardBody,
  MDBView,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBContainer,
} from "mdbreact";
import style from "./ProductDetail.module.css";

export default class ProductVarianPreview extends Component {
  state = {
    photo_number: 0,
  };

  container = null;

  handelChangePhoto = (number) => {
    // console.log("masuk");
    this.setState({ photo_number: number });
  };

  scrollLeft = () => {
    var container = document.getElementById("container");
    this.sideScroll(container, "left", 25, 100, 10);
  };

  scrollRight = () => {
    var container = document.getElementById("container");
    this.sideScroll(container, "right", 25, 100, 10);
  };

  sideScroll = (element, direction, speed, distance, step) => {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  render() {
    const { photo } = this.props;
    return (
      <>
        {photo !== undefined ? (
          <MDBCard style={{ minHeight: "500px", maxHeight: "500px" }}>
            <MDBCardBody>
              <MDBCardImage
                className="img-fluid mx-auto d-block"
                src={photo[this.state.photo_number].link}
                style={{ maxHeight: "330px" }}
                hover
              />
              <hr />
              <MDBContainer style={{ display: "flex", maxHeight: "100px" }}>
                <MDBBtn
                  size="sm"
                  color="dark"
                  outline
                  rounded
                  style={{ postion: "fixed", maxHeight: 50, marginTop: 20}}
                  onClick={() => this.scrollLeft()}
                >
                  <MDBIcon icon="angle-double-left" />
                </MDBBtn>
                <div className={style.rowProduct} id="container">
                  {photo.map((data, index) => {
                    return (
                      <img
                        key={index}
                        className="img-fluid img-thumbnail"
                        src={data.link}
                        onClick={() => this.handelChangePhoto(index)}
                        style={{ maxWidth: "100px" }}
                      />
                    );
                  })}
                </div>
                <MDBBtn
                  size="sm"
                  color="dark"
                  outline
                  rounded
                  style={{ postion: "fixed", maxHeight: 50, marginTop: 20 }}
                  onClick={() => this.scrollRight()}
                >
                  <MDBIcon icon="angle-double-right" />
                </MDBBtn>
              </MDBContainer>
            </MDBCardBody>
          </MDBCard>
        ) : (
          ""
        )}
      </>
    );
  }
}
