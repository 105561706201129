import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBLink,
} from "mdbreact";
import style from "./ProductDetail.module.css";

export default class ProductDetailForm extends Component {
  state = {
    varian: 0,
    quantity: 1,
    note_flag: false,
    note: "",
  };

  handelChangeVarian = (varian) => {
    this.setState({ varian: varian });
  };

  handelChange = (event) => {
    let { name, value } = event.target;
    let stock = this.props.product_detail.dtl_product_active[this.state.varian]
      .stock;
    if ((name === "quantity") & (value === "")) value = "1";
    if ((name === "quantity") & (parseInt(value) > parseInt(stock)))
      value = stock;
    this.setState({ [name]: value });
  };

  handleQuantity = (proses, stock) => {
    let quant =
      proses === "+"
        ? this.state.quantity < stock
          ? this.state.quantity + 1
          : stock
        : this.state.quantity <= 1
        ? 1
        : this.state.quantity - 1;
    this.setState({ quantity: quant });
  };

  hadelNote = () => {
    this.setState({ note_flag: !this.state.note_flag, note: "" });
  };

  render() {
    const product = this.props.product_detail.dtl_product_active;
    const { isSeller, sellerId, addToChartFromPage } = this.props;
    // console.log(this.props);
    return (
      <MDBCard style={{ minHeight: "500px" }}>
        <MDBCardBody>
          <MDBCardTitle>
            {this.props.name + " - " + product[this.state.varian].name}
          </MDBCardTitle>
          <hr />
          <MDBRow>
            <MDBCol size="3">HARGA</MDBCol>
            <MDBCol>
              {new Intl.NumberFormat("ID", {
                style: "currency",
                currency: "IDR",
              }).format(product[this.state.varian].price)}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol size="3">STOCK</MDBCol>
            <MDBCol>{product[this.state.varian].stock}</MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="3">VARIAN</MDBCol>
            <MDBCol>
              <MDBRow>
                {product.map((data, index) => {
                  return (
                    <MDBBtn
                      size="sm"
                      color="deep-purple"
                      key={index}
                      onClick={() => this.handelChangeVarian(index)}
                      active={this.state.varian === index}
                    >
                      {data.name}
                    </MDBBtn>
                  );
                })}
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="3">JUMLAH</MDBCol>
            <MDBCol>
              <MDBRow>
                <MDBBtn
                  size="sm"
                  color="dark"
                  outline
                  rounded
                  onClick={() =>
                    this.handleQuantity("-", product[this.state.varian].stock)
                  }
                >
                  <MDBIcon icon="minus" />
                </MDBBtn>
                <input
                  name="quantity"
                  type="number"
                  onChange={this.handelChange}
                  value={this.state.quantity}
                  className={style.input_number}
                />
                <MDBBtn
                  size="sm"
                  color="dark"
                  outline
                  rounded
                  onClick={() =>
                    this.handleQuantity("+", product[this.state.varian].stock)
                  }
                >
                  <MDBIcon icon="plus" />
                </MDBBtn>
              </MDBRow>
              <MDBRow>
                <MDBLink
                  to="#"
                  onClick={() => this.hadelNote()}
                  hidden={this.state.note_flag}
                >
                  Tulis catatan untuk penjual
                </MDBLink>
                <div
                  className="form-group w-100"
                  style={{ display: "flex", marginTop: 10 }}
                  hidden={!this.state.note_flag}
                >
                  <input
                    type="text"
                    onChange={this.handelChange}
                    className="form-control"
                    name="note"
                    value={this.state.note}
                  />
                  <MDBBtn
                    size="sm"
                    color="danger"
                    onClick={() => this.hadelNote()}
                  >
                    <MDBIcon icon="times" />
                  </MDBBtn>
                </div>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <hr />
          <MDBRow>
            <MDBCol size="3">TOTAL</MDBCol>
            <MDBCol style={{ fontSize: 20 }}>
              {new Intl.NumberFormat("ID", {
                style: "currency",
                currency: "IDR",
              }).format(product[this.state.varian].price * this.state.quantity)}
            </MDBCol>
          </MDBRow>
          {isSeller ? (
            ""
          ) : (
            <>
              <hr />
              <MDBRow end>
                {/* <MDBBtn>Beli</MDBBtn> */}
                <MDBBtn
                  color="deep-purple"
                  onClick={() =>
                    addToChartFromPage(
                      sellerId,
                      product[this.state.varian].id,
                      this.state.quantity,
                      this.state.note
                    )
                  }
                >
                  Tambah ke Keranjang
                </MDBBtn>
              </MDBRow>
            </>
          )}
        </MDBCardBody>
      </MDBCard>
    );
  }
}
