import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBBadge,
  MDBBtn,
} from "mdbreact";

export default class UserLocationListModal extends Component {
  UserLocationDetail = (data, index) => {
    const selected_id = this.props.selected_location.id;
    return (
      <div key={index}>
        <MDBCard>
          <MDBContainer style={{ maxWidth: "90%" }}>
            <MDBRow>
              <MDBCol>
                <MDBContainer style={{ padding: "5px" }}>
                  <MDBRow>
                    {`${data.name} (${data.alias}) `}
                    {data.is_primary === "1" ? (
                      <span>
                        &nbsp;
                        <MDBBadge color="deep-purple" pill>
                          Primary
                        </MDBBadge>
                      </span>
                    ) : (
                      ""
                    )}
                  </MDBRow>
                  <MDBRow>{data.hp}</MDBRow>
                  <MDBRow>{data.address}</MDBRow>
                  <MDBRow>{data.zip_id.label}</MDBRow>
                </MDBContainer>
              </MDBCol>
              {selected_id !== data.id ? (
                <MDBCol size="2" middle>
                  <MDBBtn
                    color="deep-purple"
                    onClick={() => {
                      this.props.changeUserLocationHandler(index);
                      this.props.popupModal("CLOSE", "1");
                    }}
                  >
                    Pilih
                  </MDBBtn>
                </MDBCol>
              ) : (
                ""
              )}
            </MDBRow>
          </MDBContainer>
        </MDBCard>
        <br />
      </div>
    );
  };

  render() {
    const { modal1, user_location_list, popupModal } = this.props;
    return (
      <MDBContainer>
        <MDBModal
          isOpen={modal1}
          toggle={() => popupModal("CLOSE", "1")}
          size="lg"
          centered
        >
          <MDBModalHeader toggle={() => popupModal("CLOSE", "1")}>
            Pilih Almat Pengiriman
          </MDBModalHeader>
          <MDBModalBody>
            <div className="overflow-auto" style={{ maxHeight: "250px" }}>
              {user_location_list !== undefined
                ? user_location_list.map((data, index) => {
                    return this.UserLocationDetail(data, index);
                  })
                : ""}
            </div>
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}
