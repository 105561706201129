import { MDBCol, MDBContainer, MDBRow } from "mdbreact";
import React, { Component } from "react";
import parse from "html-react-parser";
import SectionContainer from "../sectionContainer";

export default class AboutView extends Component {
  render() {
    const props = this.props;
    return (
      <MDBContainer style={{ backgroundColor: props.background_color }}>
        <SectionContainer header="ABOUT US">
          <MDBContainer>
            <MDBRow hidden={props.header_img === ""}>
              <img
                className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                src={props.header_img}
              />
            </MDBRow>
            <hr
              hidden={
                props.header_img === "" ||
                (props.company_img === "" && props.company_txt === "")
              }
            />
            {/* company */}
            <MDBRow className="mb-3" hidden={props.company_img === ""}>
              <MDBCol md="8" className="mx-auto d-block">
                <img
                  className=" img-fluid img-thumbnail z-depth-1"
                  src={props.company_img}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow
              hidden={props.company_img === "" && props.company_txt === ""}
            >
              <p style={{ width: "100%", textAlign: "center" }}>
                TENTANG PERUSAHAAN
              </p>
            </MDBRow>
            <br />
            <MDBRow hidden={props.company_txt === ""}>
              {parse(props.company_txt)}
            </MDBRow>
            {/* product */}
            <hr hidden={props.product_img === "" && props.product_txt === ""} />
            <MDBRow className="mb-3" hidden={props.product_img === ""}>
              <MDBCol md="8" className="mx-auto d-block">
                <img
                  className=" img-fluid img-thumbnail z-depth-1"
                  src={props.product_img}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow
              hidden={props.product_img === "" && props.product_txt === ""}
            >
              <p style={{ width: "100%", textAlign: "center" }}>
                TENTANG PRODUK
              </p>
            </MDBRow>
            <br />
            <MDBRow hidden={props.product_txt === ""}>
              {parse(props.product_txt)}
            </MDBRow>
            {/* other */}
            <hr hidden={props.other_img === "" && props.other_txt === ""} />
            <MDBRow className="mb-3" hidden={props.other_img === ""}>
              <MDBCol md="8" className="mx-auto d-block">
                <img
                  className=" img-fluid img-thumbnail z-depth-1"
                  src={props.other_img}
                />
              </MDBCol>
            </MDBRow>
            <br />
            <MDBRow hidden={props.other_img === "" && props.other_txt === ""}>
              <p style={{ width: "100%", textAlign: "center" }}>LAIN LAIN</p>
            </MDBRow>
            <br />
            <MDBRow hidden={props.other_txt === ""}>
              {parse(props.other_txt)}
            </MDBRow>
          </MDBContainer>
        </SectionContainer>
      </MDBContainer>
    );
  }
}
