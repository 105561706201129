import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBInput,
  MDBCardHeader,
  MDBBtn,
  MDBSelect,
  MDBDataTable,
} from "mdbreact";
import MDBFileUpload from "mdb-react-fileupload";
import styles from "./SellerProductsFormView.module.css";

const SellerProductsFormView = (props) => {
  return (
    <MDBContainer style={{ minWidth: "1000px",paddingTop:'50px' }}> 
      <MDBCard>
        <MDBCardHeader color="deep-purple">
          {props.statePage === "ADD" ? "Tambah Produk" : "Update Produk"}
        </MDBCardHeader>
        <MDBCardBody>
          {props.statePage === "EDIT" ? (
            <>
              {props.previewProductPhoto ? (
                <MDBRow className={styles.imageOuterContainer}>
                  <MDBCol className={styles.imageInnerContainer}>
                  {props.previewProductPhoto.hasOwnProperty(0) ? (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Depan"}
                        getValue={(e) => props.selectProductPhoto(e, 0)}
                        defaultFileSrc={props.previewProductPhoto[0].link}
                      />
                    ) : (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Depan"}
                        getValue={(e) => props.selectProductPhoto(e, 0)}
                      />
                    )}
                  </MDBCol>
                  <MDBCol className={styles.imageInnerContainer}>
                    {props.previewProductPhoto.hasOwnProperty(1) ? (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Depan"}
                        getValue={(e) => props.selectProductPhoto(e, 1)}
                        defaultFileSrc={props.previewProductPhoto[1].link}
                      />
                    ) : (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Depan"}
                        getValue={(e) => props.selectProductPhoto(e, 1)}
                      />
                    )}
                  </MDBCol>
                  <MDBCol className={styles.imageInnerContainer}>
                    {props.previewProductPhoto.hasOwnProperty(2) ? (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Samping"}
                        getValue={(e) => props.selectProductPhoto(e, 2)}
                        defaultFileSrc={props.previewProductPhoto[2].link}
                      />
                    ) : (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Samping"}
                        getValue={(e) => props.selectProductPhoto(e, 2)}
                      />
                    )}
                  </MDBCol>
                  <MDBCol className={styles.imageInnerContainer}>
                    {props.previewProductPhoto.hasOwnProperty(3) ? (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Atas"}
                        getValue={(e) => props.selectProductPhoto(e, 3)}
                        defaultFileSrc={props.previewProductPhoto[3].link}
                      />
                    ) : (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Atas"}
                        getValue={(e) => props.selectProductPhoto(e, 3)}
                      />
                    )}
                  </MDBCol>
                  <MDBCol className={styles.imageInnerContainer}>
                    {props.previewProductPhoto.hasOwnProperty(4) ? (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Detail"}
                        getValue={(e) => props.selectProductPhoto(e, 4)}
                        defaultFileSrc={props.previewProductPhoto[4].link}
                      />
                    ) : (
                      <MDBFileUpload
                        showRemove={false}
                        containerHeight={180}
                        messageDefault={"Gambar Tambahan"}
                        // messageReplace={"Detail"}
                        getValue={(e) => props.selectProductPhoto(e, 4)}
                      />
                    )}
                  </MDBCol>
                </MDBRow>
              ) : (
                ""
              )}{" "}
            </>
          ) : (
            <MDBRow className={styles.imageOuterContainer}>
              <MDBCol className={styles.imageInnerContainer}>
                <MDBFileUpload
                  showRemove={false}
                  containerHeight={180}
                  messageDefault={"Gambar Utama (Wajib)"}
                  messageReplace={"Utama"}
                  getValue={(e) => props.selectProductPhoto(e, 0)}
                />
              </MDBCol>
              <MDBCol className={styles.imageInnerContainer}>
                <MDBFileUpload
                  showRemove={false}
                  containerHeight={180}
                  messageDefault={"Gambar Tambahan"}
                  // messageReplace={"Depan"}
                  getValue={(e) => props.selectProductPhoto(e, 1)}
                />
              </MDBCol>
              <MDBCol className={styles.imageInnerContainer}>
                <MDBFileUpload
                  showRemove={false}
                  containerHeight={180}
                  messageDefault={"Gambar Tambahan"}
                  // messageReplace={"Samping"}
                  getValue={(e) => props.selectProductPhoto(e, 2)}
                />
              </MDBCol>
              <MDBCol className={styles.imageInnerContainer}>
                <MDBFileUpload
                  showRemove={false}
                  containerHeight={180}
                  messageDefault={"Gambar Tambahan"}
                  // messageReplace={"Atas"}
                  getValue={(e) => props.selectProductPhoto(e, 3)}
                />
              </MDBCol>
              <MDBCol className={styles.imageInnerContainer}>
                <MDBFileUpload
                  showRemove={false}
                  containerHeight={180}
                  messageDefault={"Gambar Tambahan"}
                  // messageReplace={"Detail"}
                  getValue={(e) => props.selectProductPhoto(e, 4)}
                />
              </MDBCol>
            </MDBRow>
          )}
          <MDBCol>
            <MDBInput
              value={props.name}
              onChange={props.changeHandler}
              type="text"
              className="form-control"
              name="name"
              // label={<Text pid="registerPage" tid="nameInput" />}
              label="Nama Produk"
              required
            />
            <MDBSelect
              search
              options={props.categoryActiveList}
              selected="Pilih kategori produk"
              color="primary"
              // label={<Text pid="registerPage" tid="storeTemplateInput" />}
              label="Kategori"
              getValue={props.selectProductCategory}
            />
            <MDBInput
              value={props.description}
              onChange={props.changeHandler}
              type="textarea"
              className="form-control"
              name="description"
              // label={<Text pid="registerPage" tid="nameInput" />}
              label="Deskripsi"
              required
            />
          </MDBCol>
          {props.statePage === "ADD" ? (
            <MDBRow>
              <MDBCol className={styles.btnSubmit}>
                {/* <MDBBtn onClick={props.storeSellerProduct}>Simpan</MDBBtn> */}
                <MDBBtn color="deep-purple" onClick={() => props.addModal("ADD")}>
                  Selanjutnya
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          ) : (
            <>
              <MDBRow>
                <MDBCol className={styles.btnSubmit}>
                  <MDBBtn
                    color="red"
                    onClick={() => props.resetForm(props.groupProductId)}
                  >
                    Reset
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className={styles.btnSubmit}>
                  {/* <MDBBtn onClick={props.storeSellerProduct}>Simpan</MDBBtn> */}
                  <MDBBtn
                    onClick={() =>
                      props.updateSellerProduct(props.groupProductId)
                    }
                  >
                    Simpan perubahan
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </>
          )}
        </MDBCardBody>
        {props.statePage === "EDIT" ? (
          <MDBCardBody>
            <MDBRow>
              <MDBCol>
                <MDBBtn onClick={() => props.addModal("ADD_FROM_EDIT_PAGE")}>
                  Tambah Detail Produk
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <MDBDataTable hover noBottomColumns responsive data={props.data} />
          </MDBCardBody>
        ) : (
          ""
        )}
      </MDBCard>
    </MDBContainer>
  );
};

export default SellerProductsFormView;
