import React, { Component } from "react";
import RegisterView from "../components/RegisterView/RegisterView";

import Swal from "sweetalert2";
import API from "../utils/API";

export class Register extends Component {
  _isMounted = false;
  state = {
    name: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    facebook: "",
    twitter: "",
    instagram: "",
    nameWeb: "",
    subDomain: "",
    domain: "",
    renderLoaderSub: false,
    renderLoaderDomain: false,
    isAvailableSubDomain: "",
    isAvailableDomain: "",
    isSubDomainChecked: false,
    isDomainChecked: false,
    logoToko: "",
    customLogoToko: "",
    previewLogoToko: "",
    previewCustomLogoToko: "",
    isUsingCustomLogo: false,
    bannerToko: "",
    customBannerToko: "",
    previewBannerToko: "",
    previewCustomBannerToko: "",
    isUsingCustomBanner: false,
    selectedTemplate: "",
    isTOSChecked: false,
    template: [
      {
        text: "Tema 1",
        value: "theme1",
      },
      {
        text: "Tema 2",
        value: "theme2",
      },
    ],
  };

  componentDidMount() {
    this._isMounted = true;
    this.getTemplateList();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  checkDomainAvailability = (domain, type) => {
    let link;
    if (type === "sub") {
      this.setState({ renderLoaderSub: true });
      link = domain + ".1toko.com";
    } else {
      this.setState({ renderLoaderDomain: true });
      link = domain;
    }

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.post("/auth/check_sub_domain", { web: link }, config)
      .then((res) => {
        let temp = res.data.data;
        if (!Array.isArray(temp) && type === "sub") {
          this.setState({
            isAvailableSubDomain: false,
            isSubDomainChecked: true,
            renderLoaderSub: false,
          });
        } else if (!Array.isArray(temp) && type === "domain") {
          this.setState({
            isAvailableDomain: false,
            isDomainChecked: true,
            renderLoaderDomain: false,
          });
        } else if (Array.isArray(temp) && type === "sub") {
          this.setState({
            isAvailableSubDomain: true,
            isSubDomainChecked: true,
            renderLoaderSub: false,
          });
        } else if (Array.isArray(temp) && type === "domain") {
          this.setState({
            isAvailableDomain: true,
            isDomainChecked: true,
            renderLoaderDomain: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ renderLoader: false });
        // if (err.response) {
        //   let errmessage = "";
        //   let keys = Object.keys(err.response.data.errors);
        //   for (let i = 0; i < keys.length; i++) {
        //     errmessage +=
        //       keys[i] + " " + err.response.data.errors[keys[i]][0] + "<br/>";
        //   }
        //   Swal.fire({
        //     icon: "error",
        //     html: errmessage,
        //   });
        // }
      });
  };

  selectLogoHandler = (event) => {
    if (event[0]) {
      this.setState({ customLogoToko: event[0] });
      this.setState({
        previewCustomLogoToko: URL.createObjectURL(event[0]),
        tempStateLogo: URL.createObjectURL(event[0]),
      });
    }
  };

  selectBannerHandler = (event) => {
    if (event[0]) {
      this.setState({ customBannerToko: event[0] });
      this.setState({
        previewCustomBannerToko: URL.createObjectURL(event[0]),
        tempStateBanner: URL.createObjectURL(event[0]),
      });
    }
  };

  selectTemplateHandler = (event) => {
    let tempSelected;
    tempSelected = this.state.template.find((item) => item.value === event[0]);
    if (event[0]) {
      this.setState({
        selectedTemplate: event[0],
        previewBannerToko: tempSelected.banner,
        bannerToko: tempSelected.banner,
        previewLogoToko: tempSelected.logo,
        logoToko: tempSelected.logo,
      });
    }
  };

  checkCustomBanner = () => {
    if (this.state.isUsingCustomBanner) {
      this.setState({
        isUsingCustomBanner: false,
        previewCustomBannerToko: "",
      });
    } else {
      this.setState({
        isUsingCustomBanner: true,
        previewCustomBannerToko: this.state.tempStateBanner,
      });
    }
  };

  checkCustomLogo = () => {
    if (this.state.isUsingCustomLogo) {
      this.setState({
        isUsingCustomLogo: false,
        previewCustomLogoToko: "",
      });
    } else {
      this.setState({
        isUsingCustomLogo: true,
        previewCustomLogoToko: this.state.tempStateLogo,
      });
    }
  };

  getTemplateList = () => {
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.get("/prm/template", config)
      .then((res) => {
        if (res.data.code !== "200") {
          alert(res.data.errors);
        } else {
          let templateTemp = res.data.data.template.map((template) => ({
            text: template.name,
            value: template.id.toString(),
            banner: template.banner,
            logo: res.data.data.logo,
          }));
          if (this._isMounted) {
            this.setState({ template: templateTemp });
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  submitHandler = (event) => {
    const {
      name,
      email,
      password,
      passwordConfirmation,
      nameWeb,
      domain,
      subDomain,
      selectedTemplate,
    } = this.state;

    //Swal loading
    Swal.fire({
      title: "Mohon menunggu...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    event.preventDefault();
    event.target.className += " was-validated";

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    // console.log(this.state);

    const data = new FormData();
    data.append("name", this.state.name);
    data.append("email", this.state.email);
    data.append("password", this.state.password);
    data.append("password_confirmation", this.state.passwordConfirmation);
    if (this.state.isUsingCustomLogo) {
      data.append("logo", this.state.customLogoToko);
    } else {
      data.append("link_default_logo", this.state.logoToko);
    }
    if (this.state.isUsingCustomBanner) {
      data.append("banner", this.state.customBannerToko);
    } else {
      data.append("link_default_banner", this.state.bannerToko);
    }
    data.append("template_id", this.state.selectedTemplate);
    data.append("facebook", this.state.facebook);
    data.append("twitter", this.state.twitter);
    data.append("instagram", this.state.instagram);
    data.append("name_web", this.state.nameWeb);
    data.append("subdomain", this.state.subDomain + ".1toko.com");
    data.append("domain", this.state.domain);

    const emailregex = new RegExp("^.*@.*\\..*");
    const passregex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      passwordConfirmation !== "" &&
      password === passwordConfirmation
    ) {
      if (emailregex.test(this.state.email)) {
        if (passregex.test(this.state.password)) {
          if (nameWeb !== "") {
            if (domain !== "" || subDomain !== "") {
              if (selectedTemplate !== "") {
                API.post("/auth/register_seller", data, config)
                  .then((res) => {
                    console.log(res.data);
                    if (parseInt(res.data.code) !== 200) {
                      alert(res.data.errors);
                    } else {
                      Swal.hideLoading();
                      Swal.fire({
                        icon: "success",
                        title: "Berhasil!",
                        text:
                          "Silahkan cek email anda untuk konfirmasi pendaftaran!",
                      });
                      this.props.history.push("/login");
                    }
                  })
                  .catch((err) => {
                    Swal.disableLoading();
                    console.log(err)
                  });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Sorry...",
                  text: "Please choose one of the template",
                });
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Sorry...",
                text: "Please fill the website address",
              });
            }
          } else {
            Swal.fire({
              icon: "error",
              title: "Sorry...",
              text: "Please fill website name",
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            html:
              "Password must be 6 character or more and " +
              "contain lowercase and uppercase alphabet, " +
              "and numeric character",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Sorry...",
          text: "Silahkan masukan email yang benar",
        });
      }
      // }
    } else {
      Swal.fire({
        icon: "error",
        title: "Sorry...",
        text: "Tidak boleh ada yang kosong!",
      });
    }
  };

  render() {
    return (
      <>
        <RegisterView
          {...this.state}
          isSeller={true}
          changeHandler={this.changeHandler}
          submitHandler={this.submitHandler}
          checkCustomBanner={this.checkCustomBanner}
          checkCustomLogo={this.checkCustomLogo}
          checkDomainAvailability={this.checkDomainAvailability}
          selectLogoHandler={this.selectLogoHandler}
          selectBannerHandler={this.selectBannerHandler}
          selectTemplateHandler={this.selectTemplateHandler}
        />
      </>
    );
  }
}

export default Register;
