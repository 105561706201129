import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBFooter,
} from "mdbreact";
import Banner from "../../components/HeaderAfterLogin/Banner";
import ProductHome from "../../pages/ProductHome";

// import "../css/Home.css";
// import styles from "../css/Home.module.css";

class Home extends React.Component {
  render() {
    // const containerStyle = {
    //   marginTop: "8rem",
    // };

    return (
      <div id="single">
        {/* <Banner /> */}

        <MDBContainer /* style={containerStyle} */>
          <MDBRow>
            <MDBCol>
              <Banner />
            </MDBCol>
          </MDBRow>
          <hr className="my-5" />
          <ProductHome />
        </MDBContainer>

        <MDBFooter className="pt-0">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <div className="text-center d-flex justify-content-center my-4">
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="facebook"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="twitter"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="google-plus"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="linkedin"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="instagram"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                  <a href="#!">
                    <MDBIcon
                      fab
                      icon="pinterest"
                      size="2x"
                      className="p-2 m-2 fa-lg text-white mr-md-4"
                    />
                  </a>
                </div>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      </div>
    );
  }
}

export default Home;
