import React, { Component } from "react";

export default class ImagePreview extends Component {
  render() {
    return (
      <div style={this.props.containerStyle}>
        <img
          src={this.props.img}
          alt={this.props.alt}
          style={this.props.style}
        />
      </div>
    );
  }
}
