import React, { Component } from "react";
import { MDBCard, MDBCardBody, MDBRow, MDBCol, MDBContainer } from "mdbreact";

export default class ShipmentDetailProductView extends Component {
  render() {
    const { data } = this.props;
    return (
      <>
        {data ? (
          <>
            <MDBRow>
              <MDBCol size="5">
                <img
                  className="img-fluid img-thumbnail"
                  src={data.img}
                  style={{ maxWidth: "125.65px" }}
                />
              </MDBCol>
              <MDBCol size="7">
                <MDBRow
                  style={{ fontWeight: "bold" }}
                >{`${data.product_name} - ${data.varian_name}`}</MDBRow>
                <MDBRow>
                  {new Intl.NumberFormat("ID", {
                    style: "currency",
                    currency: "IDR",
                    maximumSignificantDigits: 3,
                  }).format(data.price)}
                </MDBRow>
                <MDBRow>
                  {`${data.quantity} barang (${
                    data.weight * data.quantity
                  } gr)`}
                </MDBRow>
                {data.note === "" ? (
                  ""
                ) : (
                  <MDBRow>{`Catatan : ${data.note}`}</MDBRow>
                )}
              </MDBCol>
            </MDBRow>
            <br />
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
