import React, { Component } from "react";
import { CartContext } from "../context/CartProvider";
import UserLocationProvider, {
  UserLoactionContext,
} from "../context/UserLocationProvider";
import CartView from "../components/Cart/CartView";
import SellerPrmContext from "../context/SellerPrmContext";

class Cart extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm) => (
          <CartContext.Consumer>
            {(cartprm) => (
              <UserLocationProvider>
                <UserLoactionContext.Consumer>
                  {(userlocationprm) => (
                    <CartView
                      sellerprm={sellerprm}
                      cartprm={cartprm}
                      userlocationprm={userlocationprm}
                    />
                  )}
                </UserLoactionContext.Consumer>
              </UserLocationProvider>
            )}
          </CartContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}

export default Cart;
