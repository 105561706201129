import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
  MDBCard,
} from "mdbreact";
import AsyncSelect from "react-select/async";
// import { Text } from "../../context/Language";

class UserLocationFormModal extends Component {
  render() {
    const { isEdit } = this.props;
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.modal1}
          toggle={() => this.props.popupModal("CLOSE", "1")}
        >
          <MDBModalHeader toggle={() => this.props.popupModal("CLOSE", "1")}>
            {isEdit ? "PERBAHARUI ALAMAT" : "TAMBAH ALAMAT"}
          </MDBModalHeader>
          <MDBModalBody>
            <MDBCard>
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.alias}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="alias"
                      label="Nama Tempat"
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.hp}
                      onChange={this.props.changeHandler}
                      type="phone"
                      className="form-control"
                      name="hp"
                      label="Handphone"
                      required
                    />
                    <small>*Minimal 6 karakter</small>
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.name}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="name"
                      label="Atas Nama"
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      value={this.props.address}
                      onChange={this.props.changeHandler}
                      type="text"
                      className="form-control"
                      name="address"
                      label="Alamat"
                      required
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <MDBCol>
                    {isEdit ? (
                      <AsyncSelect
                        defaultValue={this.props.selectedAddressDefault}
                        value={this.props.selectedAddress}
                        placeholder="Ketik minimal 3 huruf untuk cari alamat"
                        loadOptions={this.props.loadOptions}
                        onChange={this.props.handleChangeSelect}
                        cacheOptions
                      />
                    ) : (
                      <AsyncSelect
                        defaultInputValue=""
                        value={this.props.selectedAddress}
                        placeholder="Ketik minimal 3 huruf untuk cari alamat"
                        loadOptions={this.props.loadOptions}
                        onChange={this.props.handleChangeSelect}
                        cacheOptions
                      />
                    )}
                  </MDBCol>
                </MDBRow>
                <MDBRow>
                  <br />
                </MDBRow>
              </MDBContainer>
            </MDBCard>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              color="success"
              onClick={() =>
                isEdit
                  ? this.props.updateUserLocation()
                  : this.props.storeUserLocation()
              }
            >
              {isEdit ? "PERBAHARUI" : "TAMBAH"}
            </MDBBtn>
            <MDBBtn
              outline
              color="success"
              onClick={() => this.props.popupModal("CLOSE", "1")}
            >
              BATAL
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default UserLocationFormModal;
