import { MDBBtn, MDBCol, MDBContainer, MDBDataTable, MDBRow } from "mdbreact";
import React, { Component } from "react";

export default class BankAccountView extends Component {
  render() {
    const {data, popupModal} = this.props;
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol>
            <MDBBtn
              color="deep-purple"
              onClick={() => popupModal("ADD", "1")}
            >
              TAMBAH REKENING
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBDataTable
              hover
              noBottomColumns
              responsive
              fixed
              data={data}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}
