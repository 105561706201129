import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import Product from "./pages/Product";
import SellerLocation from "../pages/SellerLocation";
import SellerCategory from "../pages/SellerCategory";
import SellerProducts from "../pages/SellerProducts";
import SellerProductsForm from "../pages/SellerProductsForm";
import ProfileUser from "../pages/ProfileUser";
import ProfileSeller from "../pages/ProfileSeller";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/product" exact component={Product} />
        <Route path="/admin/sellerlocation" exact component={SellerLocation} />
        <Route path="/admin/sellercategory" exact component={SellerCategory} />
        <Route path="/admin/sellerproducts" exact component={SellerProducts} />
        <Route path="/admin/sellerproducts/add-product" exact component={SellerProductsForm} />
        <Route path="/admin/sellerproducts/edit-product/:id" exact component={SellerProductsForm} />
        <Route path='/admin/sellerprofile' exact component={ProfileSeller} />
        <Route path='/profile' exact component={ProfileUser} />
      </Switch>
    );
  }
}

export default Routes;
