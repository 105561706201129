const id = {
  landing: {
    welcome: "Selamat datang di 1toko",
    loginBtn: "Masuk",
    registerBtn: "Daftar",
    landingRegisterBtn: "Daftar Sekarang",
  },
  registerPage: {
    makeAccountTitle: "BUAT AKUN BARU",
    toRegister: "Sudah punya akun?",
    submitRegister: "Daftar",
    emailInput: "Email",
    nameInput: "Nama",
    passInput: "Kata sandi",
    passConfirmInput: "Konfirmasi kata sandi",
    setupStoreTitle: "Pengaturan Toko",
    storeNameInput: "Nama Toko",
    subDomainInput: "Alamat Website 1toko.com",
    domainInput: "Alamat Website External (Opsional)",
    domainInputHint:
      'Gunakan domain URL yang benar untuk toko anda seperti "contoh.com"',
    storeTemplateInput: "Template Toko",
    isCustomBannerCheck: "Gunakan banner custom?",
    isCustomLogoCheck: "Gunakan logo custom?",
    logoStoreInput: "Logo Toko",
    bannerStoreInput: "Banner Toko",
    checkingDomain: "Checking domain...",
    availableDomain: "Domain is available!",
    unavailableDomain: "Domain is not available, please use other name",
  },
  loginPage: {
    forgotPass: "Lupa Password?",
    login: "Masuk",
    toRegister: "Belum punya akun?",
    emailInput: "Email",
    passInput: "Kata Sandi",
    rememberMeCheck: "Ingat Saya",
  },
  t1_header: {
    productBtn: "Produk",
    aboutBtn: "Tentang",
    faqBtn: "FAQ",
    cartBtn: "Keranjang",
    loginBtn: "Masuk"
  },
  t2_header: {
    productBtn: "Produk",
    aboutBtn: "Tentang",
    faqBtn: "FAQ",
    cartBtn: "Keranjang",
    loginBtn: "Masuk",
    profileBtn: "Profil",
  },
  sellerMenu_header: {
    sellerLocationBtn: "Atur Lokasi Penjual",
    sellerCategoryBtn: "Atur Kategori",
    sellerProfileBtn: "Profil Penjual",
    sellerAboutSettingBtn: "Profil Toko",
    sellerProductBtn: "Atur Produk",
  },
  userMenu_header: {
    helloText: "Halo, ",
    profileBtn: "Profil",
    logoutBtn: "Keluar",
  },
  changePass: {
    title: "Atur Ulang Kata Sandi",
    title2: "Ubah Kata Sandi",
    old_passInput: "Kata Sandi Lama",
    new_passInput: "Kata Sandi Baru",
    conf_passInput: "Konfirmasi Kata Sandi",
    submitBtn: "Kirim",
    closeBtn : "Tutup"
  },
  profileUser:{
    title:"Profil Pengguna",
    tap_1:"Profil",
    tap_2:"Lokasi Pengguna",
    name:"Nama",
    email:"E-mail",
    submitBtn : "Simpan Profile",
    editBtn: "Ubah Profil",
    cancelBtn:"Batal",
    changePassBtn : "Ubah Kata Sandi",
  },
  profileSeller:{
    title : "Profil Penjual",
  }
};

export default id;
