import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardFooter,
  MDBCol,
  MDBRow,
} from "mdbreact";
import React, { Component } from "react";

export default class StoreView extends Component {
  render() {
    const { logo, name_web, instagram, twitter, facebook, link } = this.props;
    return (
      <MDBCard>
        <MDBCardBody>
          <MDBRow style={{ height: "140px" }}>
            <MDBCol size="4" middle>
              <img
                className="img-fluid img-thumbnail z-depth-2"
                src={logo}
                style={{ maxWidth: "125.65px" }}
              />
            </MDBCol>
            <MDBCol size="8" middle>
              <MDBRow>Store Name :{name_web}</MDBRow>
              <MDBRow>Facebook   :{facebook}</MDBRow>
              <MDBRow>Twitter    :{name_web}</MDBRow>
              <MDBRow>Instagram  :{instagram}</MDBRow>
              <MDBRow end>
                <MDBBtn>Kunjungi</MDBBtn>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
