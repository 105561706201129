import React, { Component } from "react";
import { MDBRow, MDBCol, MDBIcon, MDBBtn, MDBBadge, MDBInput } from "mdbreact";
import style from "../ProductDetail/ProductDetail.module.css";

export default class CartDetailView extends Component {
  render() {
    const {
      cartIndex,
      sellerId,
      cart_detail,
      deleteCart,
      changeHandlerNote,
      changeQuantityHandler,
      quantityAction,
      changeHandlerChecked,
    } = this.props;
    return (
      <>
        {cart_detail.map((data, index) => {
          return (
            <div key={data.detail_id}>
              {index !== 0 ? <br /> : ""}
              <MDBRow>
                <MDBCol size="1" middle style={{ paddingLeft: "0" }}>
                  <MDBInput
                    id={"checked"+data.detail_id}
                    label=" "
                    type="checkbox"
                    checked={parseInt(data.is_selected) === 1 ? true : false}
                    onChange={() => changeHandlerChecked(cartIndex,index, sellerId)}
                    filled
                  />
                </MDBCol>
                <MDBCol size="3">
                  <img
                    className="img-fluid img-thumbnail z-depth-2"
                    src={data.img}
                    style={{ maxWidth: "125.65px" }}
                  />
                </MDBCol>
                <MDBCol size="8">
                  <MDBRow>{`${data.product_name} - ${data.varian_name}`}</MDBRow>
                  <MDBRow>
                    {new Intl.NumberFormat("ID", {
                      style: "currency",
                      currency: "IDR",
                    }).format(data.price)}
                  </MDBRow>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol bottom>
                  <MDBBadge color="info">Catatan</MDBBadge>
                  <input
                    type="text"
                    name="note"
                    value={data.note}
                    placeholder="Tambah Catatan untuk penjual"
                    className={style.input_text}
                    onChange={(e) => changeHandlerNote(e, cartIndex, index, sellerId)}
                  />
                </MDBCol>
                <MDBCol bottom>
                  <MDBRow end style={{ paddingRight: 10 }}>
                    <MDBBtn
                      size="sm"
                      outline
                      rounded
                      color="danger"
                      onClick={() => deleteCart(data.detail_id, sellerId)}
                    >
                      <MDBIcon icon="trash" />
                    </MDBBtn>
                    <MDBBtn
                      size="sm"
                      color="dark"
                      outline
                      rounded
                      onClick={() =>
                        quantityAction("-", cartIndex, index, sellerId)
                      }
                    >
                      <MDBIcon icon="minus" />
                    </MDBBtn>
                    <input
                      name="quantity"
                      type="number"
                      onFocus={(e)=>{e.target.select()}}
                      onChange={(e) =>
                        changeQuantityHandler(e, cartIndex, index, sellerId)
                      }
                      value={data.quantity}
                      className={style.input_number}
                    />
                    <MDBBtn
                      size="sm"
                      color="dark"
                      outline
                      rounded
                      onClick={() =>
                        quantityAction("+", cartIndex, index, sellerId)
                      }
                    >
                      <MDBIcon icon="plus" />
                    </MDBBtn>
                  </MDBRow>
                </MDBCol>
              </MDBRow>
            </div>
          );
        })}
      </>
    );
  }
}
