import React, { createContext, Component } from "react";
import { MDBBtn, MDBIcon } from "mdbreact";

//import for provider
import API from "../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { withRouter } from "react-router-dom";

//Context
export const UserLoactionContext = createContext();

class UserLocationProvider extends Component {
  state = {
    //data
    name: "",
    hp: "",
    alias: "",
    address: "",
    selectedAddress: "",
    selectedAddressDefault: "",

    //data buat update
    id: "",

    //data table
    zipCode: "",
    data: [],
    dataTable: {},

    //other
    isEdit: false,
    modal1: false,
    isFromcart: false,
  };

  //handler
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeSelect = (selectedAddress) => {
    this.setState({ selectedAddress });
  };

  //get Data
  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getZipLocation = () => {
    API.get(`/prm/zipcode`, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        if (parseInt(res.data.code) !== 200) {
          console.log(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          this.setState({ zipCode: zipCodeTemp, isLoading: false });
        }
      })
      .catch((error) => {
        this.getErrorApi(error);
      });
  };

  listUserLocation = () => {
    API.get("/user_location", this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        if (res.data.data) {
          let listDataTemp = res.data.data.user_location.map((list) => ({
            id: list.id,
            user_id: list.user_id,
            alias: list.alias,
            name: list.name,
            hp: list.hp,
            address: list.address,
            zip_id: {
              label:
                list.zip.province +
                ", " +
                list.zip.sub_province +
                ", " +
                list.zip.district +
                ", " +
                list.zip.sub_district +
                ", " +
                list.zip.zip,
              value: list.zip.id,
            },
            latitude: list.latitude,
            longitude: list.longitude,
            is_primary: list.is_primary,
            created_at: list.created_at,
            updated_at: list.updated_at,
            text: list.alias,
            value: list.id,
          }));
          let dataTableTemp = {
            columns: [
              {
                label: "Atas Nama",
                field: "name",
                sort: "asc",
              },
              {
                label: "Alamat",
                field: "address",
                sort: "asc",
              },
              {
                label: "Alamat Lengkap",
                field: "zipId",
                sort: "asc",
              },
              {
                label: "Alamat Utama",
                field: "isPrimary",
                sort: "asc",
              },
              {
                label: "Action",
                field: "action",
              },
            ],
            rows: listDataTemp.map((list) => ({
              name: (
                <>
                  <div className="font-weight-bold">{list.name}</div>
                  <br />
                  <div>{list.hp}</div>
                </>
              ),
              address: (
                <>
                  <div className="font-weight-bold">{list.alias}</div>
                  <br />
                  <div>{list.address}</div>
                </>
              ),
              zipId: list.zip_id.label,
              isPrimary: parseInt(list.is_primary) === 1 ? "Utama" : "",
              action: (
                <>
                  <MDBBtn
                    size="sm"
                    color="primary"
                    onClick={() => this.editUserLocation(list)}
                  >
                    <MDBIcon icon="edit" className="mr-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color="danger"
                    onClick={() => this.deleteUserLocation(list.id)}
                  >
                    <MDBIcon icon="trash-alt" className="mr-1" />
                  </MDBBtn>
                  {parseInt(list.is_primary) === 0 ? (
                    <>
                      <br />
                      <MDBBtn
                        size="sm"
                        color="success"
                        onClick={() => this.bePrimarySellerLocation(list.id)}
                      >
                        <MDBIcon icon="warehouse" className="mr-1" />
                        Jadikan Utama
                      </MDBBtn>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ),
            })),
          };
          this.setState({
            data: listDataTemp,
            dataTable: dataTableTemp,
          });
        }
      })
      .catch((error) => {
        this.getErrorApi(error);
      });
  };

  loadOptions = (inputValuePrm, callback) => {
    let inputValue = inputValuePrm ? `keyword=${inputValuePrm}` : "";
    let limit = "&limit=50";
    // let limit = limitPrm ? `&limit=${limitPrm}` : "";

    if (inputValuePrm.length >= 3) {
      API.get(
        `/prm/zipcode?${inputValue}${limit}`,
        this.createConfig(Cookies.get("access_token"))
      ).then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let zipCodeTemp = res.data.data.zipcode.map((zipCode) => ({
            value: zipCode.value, //id
            label: zipCode.label.toString(), //address
          }));
          callback(zipCodeTemp);
        }
      });
    }
  };

  //modification Data
  storeUserLocation = () => {
    Swal.fire({
      title: "Menambahkan...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const data = new FormData();
    data.append("name", this.state.name);
    data.append("hp", this.state.hp);
    data.append("alias", this.state.alias);
    data.append("zip_id", this.state.selectedAddress.value);
    data.append("address", this.state.address);
    data.append("latitude", null);
    data.append("longitude", null);
    API.post(
      "/user_location",
      data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Berhasil menambahkan alamat",
        }).then((res) => {
          this.popupModal("CLOSE", "1");
          if(this.state.isFromcart) {
            this.props.history.push('/delivery')
          }else{
            this.listUserLocation();
          }
        });
      })
      .catch((error) => {
        console.log(error)
        // this.getErrorApi(error);
      });
  };

  editUserLocation = async (list) => {
    // console.log(list);
    this.setState({
      id: list.id,
      name: list.name,
      hp: list.hp,
      alias: list.alias,
      selectedAddress: list.zip_id,
      selectedAddressDefault: list.zip_id,
      address: list.address,
    });
    this.popupModal("UPDATE", "1");
  };

  updateUserLocation = () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    const data = {
      name: this.state.name,
      hp: this.state.hp,
      alias: this.state.alias,
      zip_id: this.state.selectedAddress.value,
      address: this.state.address,
      latitude: null,
      longitude: null,
    };

    API.put(
      "user_location/" + this.state.id,
      data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        Swal.fire({
          icon: "success",
          text: "Berhasil mengubah alamat",
        }).then((res) => {
          this.listUserLocation();
          this.popupModal("CLOSE", "1");
        });
      })
      .catch((error) => {
        this.getErrorApi(error);
      });
  };

  deleteUserLocation = (location_id) => {
    Swal.fire({
      title: "Anda yakin akan menghapus alamat ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((res) => {
      if (res.value) {
        API.delete(
          "user_location/" + location_id,
          this.createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Alamat telah berhasil dihapus",
            }).then((res) => {
              this.listUserLocation();
            });
          })
          .catch((error) => {
            this.getErrorApi(error);
          });
      } else {
        Swal.close();
      }
    });
  };

  bePrimarySellerLocation = (addressId) => {
    Swal.fire({
      title: "Anda yakin jadikan alamat ini sebagai alamat utama?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((res) => {
      if (res.value) {
        API.put(
          "user_location/set_primary/" + addressId,
          null,
          this.createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text: "Alamat utama anda telah dirubah",
              type: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {});
      } else {
        Swal.close();
      }
    });
  };

  //modal function
  popupModal = (act, no, from = false) => {
    let modal = "modal" + no;
    if (act === "CLOSE") {
      this.setState({
        [modal]: !this.state[modal],
        isEdit: false,
        id: "",
        name: "",
        hp: "",
        alias: "",
        address: "",
        selectedAddress: "",
        selectedAddressDefault: "",
      });
    } else if (act === "ADD") {
      this.setState({
        [modal]: !this.state[modal],
        isEdit: false,
        isFromcart: from,
      });
    } else if (act === "UPDATE") {
      this.setState({ [modal]: !this.state[modal], isEdit: true });
    }
  };

  //other
  getErrorApi = (error) => {
    Swal.disableLoading();
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      if (error.response.data.errors) {
        let errmessage = "";
        let keys = Object.keys(error.response.data.errors);
        for (let i = 0; i < keys.length; i++) {
          errmessage +=
            keys[i] + " " + error.response.data.errors[keys[i]][0] + "<br/>";
        }
        Swal.fire({
          icon: "error",
          html: errmessage,
        });
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      console.log(error.request);
    } else {
      // Something happened in setting up the request and triggered an Error
      console.log("Error", error.message);
    }
    console.log(error.config);
  };

  render() {
    return (
      <UserLoactionContext.Provider
        value={{
          ...this.state,
          changeHandler: this.changeHandler,
          handleChangeSelect: this.handleChangeSelect,
          getZipLocation: this.getZipLocation,
          listUserLocation: this.listUserLocation,
          loadOptions: this.loadOptions,
          storeUserLocation: this.storeUserLocation,
          editUserLocation: this.editUserLocation,
          updateUserLocation: this.updateUserLocation,
          deleteUserLocation: this.deleteUserLocation,
          bePrimarySellerLocation: this.bePrimarySellerLocation,
          popupModal: this.popupModal,
        }}
      >
        {this.props.children}
      </UserLoactionContext.Provider>
    );
  }
}

export default withRouter(UserLocationProvider);
