import { MDBContainer } from "mdbreact";
import React, { Component } from "react";
import API from "../../utils/API";
import Cookies from "js-cookie";
import AboutView from "./AboutView";

export default class AboutViewMain extends Component {
  state = {
    id: "",
    background_color: "#fff",
    header_img: "",
    company_img: "",
    product_img: "",
    other_img: "",

    company_txt: "",
    product_txt: "",
    other_txt: "",
  };

  componentDidMount() {
    this.getAbout(this.props.sellerPrm.web_link);
  }

  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getAbout = (web_link) => {
    web_link = web_link.substring(0, web_link.indexOf("."));
    API.get(`about/${web_link}`, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        const data = res.data.data.about;
        if (data !== null) {
          let header_img = data.header_image === null ? "" : data.header_image;
          let company_img =
            data.company_image === null ? "" : data.company_image;
          let product_img =
            data.product_image === null ? "" : data.product_image;
          let other_img = data.other_image === null ? "" : data.other_image;

          this.setState({
            id: data.id,
            background_color: data.background_color,
            company_txt: data.about_company === null ? "" : data.about_company,
            product_txt: data.about_product === null ? "" : data.about_product,
            other_txt: data.about_other === null ? "" : data.about_other,

            header_img: header_img,
            company_img: company_img,
            product_img: product_img,
            other_img: other_img,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <MDBContainer style={{ minWidth: "1000px", paddingTop: "50px" }}>
        <AboutView {...this.state} />
      </MDBContainer>
    );
  }
}
