import React, { Component } from 'react';
import { MDBContainer, MDBCard, MDBDataTable, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

class UserLocationView extends Component {
    render() { 
        // console.log(this.props)
        return ( 
            <MDBContainer style={{minWidth:'1000px'}}>
                <MDBRow>    
                    <MDBCol>
                        <MDBBtn
                            color='deep-purple'
                            onClick ={()=>this.props.popupModal("ADD","1")}
                        >TAMBAH ALAMAT</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol>
                        <MDBDataTable
                            hover
                            noBottomColumns
                            responsive
                            data={this.props.data}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    }
}

export default UserLocationView;
