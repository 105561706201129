import React from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBMask,
  MDBIcon,
  MDBView,
  MDBBtn,
  MDBFooter,
  MDBInput,
} from "mdbreact";
import "../components/LandingView/Landing.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Text } from "../context/Language";
import { withRouter } from "react-router-dom";
// import satuTokoLogo from "../assets/toko1_ORIGINAL.askdlaskda.png";

class Landing extends React.Component {
  state = {
    search: "",
  };

  render() {
    return (
      <div id="landing">
        <section id="home">
          <MDBView>
            <MDBMask
              className="d-flex justify-content-center align-items-center"
              overlay="gradient"
            >
              <MDBContainer className="d-flex justify-content-center align-items-center">
                <MDBCol style={{ paddingLeft: "25%" }}>
                  <MDBRow className="d-flex w-75 justify-content-center align-items-center">
                    <div className="white-text">
                      <h1
                        className="h1-responsive font-weight-bold wow fadeInLeft"
                        data-wow-delay="0.3s"
                      >
                        <Text pid="landing" tid="welcome" />
                      </h1>
                      <hr
                        className="hr-light wow fadeInLeft"
                        data-wow-delay="0.3s"
                      />
                    </div>
                  </MDBRow>
                  <MDBRow className="d-flex w-75 justify-content-center align-items-center">
                    <div className="input-group w-75">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon">
                          <i className="fa fa-search prefix"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        value={this.state.search}
                        onChange={(e)=>{this.setState({search:e.target.value})}}
                        className="form-control form-control-lg"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon"
                      />
                    </div>
                    <div className="input-group-prepend">
                      <button
                        type="button"
                        className="btn btn-light"
                        style={{ marginLeft: "0" }}
                        onClick={()=>{this.props.history.push(`/search/${this.state.search}`);}}
                      >
                        SEARCH
                      </button>
                    </div>
                  </MDBRow>
                </MDBCol>
              </MDBContainer>
            </MDBMask>
          </MDBView>
        </section>
        <MDBFooter className="pt-3 mt-5 text-center text-md-left">
          <MDBContainer>
            <MDBRow>
              <MDBCol md="3">
                <h6 className="text-uppercase font-weight-bold">
                  Footer Content
                </h6>
                <p>
                  Here you can use rows and columns here to organize your footer
                  content.
                </p>
              </MDBCol>
              <hr className="w-100 clearfix d-md-none" />
              <MDBCol md="2" className="ml-auto">
                <h6 className="text-uppercase font-weight-bold">Links</h6>
                <ul className="list-unstyled">
                  <li>
                    <a href="#!">Link 1</a>
                  </li>
                  <li>
                    <a href="#!">Link 2</a>
                  </li>
                  <li>
                    <a href="#!">Link 3</a>
                  </li>
                  <li>
                    <a href="#!">Link 4</a>
                  </li>
                </ul>
              </MDBCol>
              <hr className="w-100 clearfix d-md-none" />
              <MDBCol md="2" className="ml-auto">
                <h6 className="text-uppercase font-weight-bold">Links</h6>
                <ul className="list-unstyled">
                  <li>
                    <a href="#!">Link 1</a>
                  </li>
                  <li>
                    <a href="#!">Link 2</a>
                  </li>
                  <li>
                    <a href="#!">Link 3</a>
                  </li>
                  <li>
                    <a href="#!">Link 4</a>
                  </li>
                </ul>
              </MDBCol>
              <hr className="w-100 clearfix d-md-none" />
              <MDBCol md="2" className="ml-auto">
                <h6 className="text-uppercase font-weight-bold">Links</h6>
                <ul className="list-unstyled">
                  <li>
                    <a href="#!">Link 1</a>
                  </li>
                  <li>
                    <a href="#!">Link 2</a>
                  </li>
                  <li>
                    <a href="#!">Link 3</a>
                  </li>
                  <li>
                    <a href="#!">Link 4</a>
                  </li>
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <hr />
          <MDBContainer>
            <MDBRow>
              <MDBCol md="12">
                <ul className="list-unstyled d-flex justify-content-center mb-0 pb-0 pt-2 list-inline">
                  <li className="list-inline-item">
                    <MDBIcon
                      fab
                      icon="facebook"
                      size="2x"
                      className="white-text p-2 m-2"
                    />
                  </li>
                  <li className="list-inline-item">
                    <MDBIcon
                      fab
                      icon="twitter"
                      size="2x"
                      className="white-text p-2 m-2"
                    />
                  </li>
                  <li className="list-inline-item">
                    <MDBIcon
                      fab
                      icon="google-plus"
                      size="2x"
                      className="white-text p-2 m-2"
                    />
                  </li>
                  <li className="list-inline-item">
                    <MDBIcon
                      fab
                      icon="linkedin"
                      size="2x"
                      className="white-text p-2 m-2"
                    />
                  </li>
                  <li className="list-inline-item">
                    <MDBIcon
                      fab
                      icon="instagram"
                      size="2x"
                      className="white-text p-2 m-2"
                    />
                  </li>
                  <li className="list-inline-item">
                    <MDBIcon
                      fab
                      icon="pinterest"
                      size="2x"
                      className="white-text p-2 m-2"
                    />
                  </li>
                </ul>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBFooter>
      </div>
    );
  }
}

export default withRouter(Landing);
