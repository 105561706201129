import React, { createContext, Component } from "react";
//import for provider
import API from "../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

//Context
export const CartContext = createContext();

//Provider
var debounce = require("lodash.debounce");

class CartProvider extends Component {
  state = {
    //cart
    cart_list: undefined,
    total_price: 0,
    total_product: 0,
    select_all: true,

    //location
    user_location_num: 0,
  };

  //get data
  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  getCartList = (sellerId) => {
    API.get(`cart/${sellerId}`, this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        let temp_cart_list = res.data.data.cart;
        if (temp_cart_list.length > 0) {
          temp_cart_list = temp_cart_list.map((cart, index) => {
            let temp_detail = cart.dtl_cart.map((data) => ({
              detail_id: data.id,
              product_id: data.dtl_product.id,
              product_name: data.group_product.name,
              varian_name: data.dtl_product.name,
              price: parseInt(data.dtl_product.price),
              quantity: parseInt(data.qty),
              note: data.note,
              stock: parseInt(data.dtl_product.stock),
              weight: parseInt(data.dtl_product.weight),
              sub_total: parseInt(data.dtl_product.price) * parseInt(data.qty),
              img: data.group_product.thumbnail.link,
              is_selected: data.checked,
            }));

            let total = this.calculateCartTotal(temp_detail);
            return {
              cart_id: cart.id,
              is_selected: true,
              seller_location: cart.seller_location,
              cart_detail: temp_detail,
              total_price: total.subtotal_price,
              total_product: total.subtotal_product,
            };
          });
          this.setState({ cart_list: temp_cart_list });
          this.calculateTotal(this.state.cart_list);
          this.checkSelectGroupProduct();
          this.checkSelectAllProduct();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //init data or checked data
  checkLUserLoaction = () => {
    API.get("/user_location", this.createConfig(Cookies.get("access_token")))
      .then((res) => {
        let temp_loc_num = res.data.data.user_location.length;
        this.setState({ user_location_num: temp_loc_num });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  calculateCartTotal = (list) => {
    let temp_price =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.sub_total);
            }, 0)
        : 0;
    let temp_product =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.quantity);
            }, 0)
        : 0;
    return {
      subtotal_price: temp_price,
      subtotal_product: temp_product,
    };
  };

  calculateTotal = (list) => {
    let temp_price = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.total_price);
    }, 0);
    let temp_product = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.total_product);
    }, 0);

    this.setState({
      total_price: temp_price,
      total_item: temp_product,
    });
  };

  //data modification
  //checked
  checkSelectAllProduct = () => {
    let tempData = this.state.cart_list.find((cart) =>
      cart.cart_detail.find((d) => {
        return parseInt(d.is_selected) === 0;
      })
    );
    this.setState({ select_all: !tempData ? true : false });
  };

  checkSelectGroupProduct = (cart_index) => {
    let cartUpdate = [...this.state.cart_list];
    if (cart_index !== undefined) {
      let cart = cartUpdate[cart_index];
      let checked_detail = cart.cart_detail.find((d) => {
        return parseInt(d.is_selected) === 0;
      });
      cart.is_selected = !checked_detail ? true : false;
      this.setState({ cart_list: cartUpdate });
    } else {
      const temp_cart = this.state.cart_list.map((cart) => {
        const temp_data = cart.cart_detail.find(
          (d) => parseInt(d.is_selected) === 0
        );
        return { ...cart, is_selected: !temp_data ? true : false };
      });
      this.setState({ cart_list: temp_cart });
    }
  };

  changeHandlerCheckedAll = (sellerId) => {
    this.setState({ select_all: !this.state.select_all }, async () => {
      let cartUpdate = [...this.state.cart_list];
      cartUpdate.map((cart, index) => {
        cart.cart_detail.map((detail) => {
          console.log(detail);
          detail.is_selected = this.state.select_all ? 1 : 0;
        });
      });
      await this.setState({ cart_list: cartUpdate });
      await this.checkSelectGroupProduct();
      this.updateAllChecked(sellerId);
    });
  };

  updateAllChecked = (sellerId) => {
    const sent_data = new FormData();
    sent_data.append("check_value", this.state.select_all ? 1 : 0);
    API.post(
      `cart/${sellerId}/check_cart`,
      sent_data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        console.log(res);
        this.getCartList(sellerId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeHandlerChecked = (cart_index, index, sellerId) => {
    let cartUpdate = [...this.state.cart_list];
    let detail = cartUpdate[cart_index].cart_detail[index];
    detail.is_selected = detail.is_selected === "1" ? "0" : "1";
    const quantityUpdate = detail.quantity;
    const noteUpdate = detail.note;
    const productIdUpdate = detail.product_id;
    const detail_id = detail.detail_id;
    const checked = detail.is_selected;
    this.checkSelectAllProduct();
    this.checkSelectGroupProduct(cart_index);
    this.setState({ cart_detail: cartUpdate }, () => {
      const data = new FormData();
      data.append("product_id", productIdUpdate);
      data.append("dtl_carts_id", detail_id);
      data.append("qty", quantityUpdate);
      data.append("note", noteUpdate);
      data.append("checked", checked);
      API.post(
        `/cart/${sellerId}`,
        data,
        this.createConfig(Cookies.get("access_token"))
      )
        .then((res) => {
          console.log(res);
          this.getCartList(sellerId);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  changeHandlerCheckedGroup = (cart_index, seller_location, sellerId) => {
    let cartUpdate = [...this.state.cart_list];
    let checked = !cartUpdate[cart_index].is_selected;
    cartUpdate[cart_index].is_selected = checked;
    cartUpdate[cart_index].cart_detail.map((d) => {
      d.is_selected = checked ? 1 : 0;
    });
    this.setState({ cart_list: cartUpdate }, () => {
      this.checkSelectAllProduct();
      this.updateCheckedGroup(seller_location, cart_index, sellerId);
    });
  };

  updateCheckedGroup = (seller_location, cart_index, sellerId) => {
    const sent_data = new FormData();
    sent_data.append("seller_location_id", seller_location);
    sent_data.append(
      "check_value",
      this.state.cart_list[cart_index].is_selected ? 1 : 0
    );
    API.post(
      `/cart/${sellerId}/check_cart_group`,
      sent_data,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        console.log(res);
        this.getCartList(sellerId);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // checked end

  //quantity
  quantityAction = (action, cart_index, index, sellerId) => {
    let cartUpdate = [...this.state.cart_list];
    let detail = cartUpdate[cart_index].cart_detail[index];
    detail.quantity =
      action === "+"
        ? detail.quantity >= detail.stock
          ? detail.stock
          : detail.quantity + 1
        : detail.quantity <= 1
        ? 1
        : detail.quantity - 1;
    this.handleUpQty(cartUpdate, cart_index, index, sellerId);
  };

  changeQuantityHandler = (event, cart_index, index, sellerId) => {
    let cartUpdate = [...this.state.cart_list];
    let detail = cartUpdate[cart_index].cart_detail[index];
    let temp_qty = event.target.value;
    let stock = detail.stock;
    detail.quantity = temp_qty <= 0 ? 0 : temp_qty > stock ? stock : temp_qty;
    this.handleUpQty(cartUpdate, cart_index, index, sellerId);
  };

  handleUpQty = (cartUpdate, cart_index, index, sellerId) => {
    let detail = cartUpdate[cart_index].cart_detail[index];
    const quantityUpdate = detail.quantity;
    const noteUpdate = detail.note;
    const productIdUpdate = detail.product_id;
    const detail_id = detail.detail_id;
    const checked = detail.is_selected;
    this.setState({ cart_list: cartUpdate }, () => {
      this.updateCart(
        sellerId,
        productIdUpdate,
        detail_id,
        quantityUpdate,
        noteUpdate,
        checked
      );
    });
  };
  //quantity end

  //note
  changeHandlerNote = (event, cart_index, index, sellerId) => {
    let cartUpdate = [...this.state.cart_list];
    let detail = cartUpdate[cart_index].cart_detail[index];
    detail.note = event.target.value;
    const quantityUpdate = detail.quantity;
    const noteUpdate = detail.note;
    const productIdUpdate = detail.product_id;
    const detail_id = detail.detail_id;
    const checked = detail.is_selected;
    this.setState({ cart_detail: cartUpdate }, () => {
      this.updateCart(
        sellerId,
        productIdUpdate,
        detail_id,
        quantityUpdate,
        noteUpdate,
        checked
      );
    });
  };
  //note end

  //update API
  updateCart = debounce(
    (sellerId, product_id, detail_id, quantity, note, checked) => {
      const data = new FormData();
      data.append("product_id", product_id);
      data.append("dtl_carts_id", detail_id);
      data.append("qty", quantity);
      data.append("note", note);
      data.append("checked", checked);
      API.post(
        `/cart/${sellerId}`,
        data,
        this.createConfig(Cookies.get("access_token"))
      )
        .then((res) => {
          console.log(res);
          this.getCartList(sellerId);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    1000
  );

  //Delete API
  deleteCart = (cart_detail_id, sellerId) => {
    Swal.fire({
      title: "Anda yakin akan menghapus barang ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((res) => {
      if (res.value) {
        API.delete(
          `cart/${cart_detail_id}`,
          this.createConfig(Cookies.get("access_token"))
        )
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Barang berhasil dihapus",
            }).then((res) => {
              this.getCartList(sellerId);
              // window.location.reload();
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Swal.close();
      }
    });
  };

  render() {
    return (
      <CartContext.Provider
        value={{
          ...this.state,
          getCartList: this.getCartList,
          deleteCart: this.deleteCart,
          changeHandlerNote: this.changeHandlerNote,
          changeQuantityHandler: this.changeQuantityHandler,
          quantityAction: this.quantityAction,
          checkLUserLoaction: this.checkLUserLoaction,
          changeHandlerChecked: this.changeHandlerChecked,
          changeHandlerCheckedAll: this.changeHandlerCheckedAll,
          changeHandlerCheckedGroup: this.changeHandlerCheckedGroup,
        }}
      >
        {this.props.children}
      </CartContext.Provider>
    );
  }
}

export default CartProvider;
