import React, { Component } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBCard,
    MDBBtn,
} from "mdbreact";
import styles from "../LoginView/LoginView.module.css";
import { Text } from "../../context/Language";

export default class CPTokenView extends Component{
    constructor(props){
        super(props);
        this.state={};
    }

    render(){
        return(
            <div className={styles.outerContainer}>
                <div className={[styles.container].join(" ")}>
                    <MDBCard className={styles.card}>
                        <div className={styles.title}>
                            <Text pid="changePass" tid="title" />
                        </div>
                        <form
                            className="needs-validation"
                            onSubmit={this.props.changePassToken}
                            noValidate
                        >
                            <MDBContainer className={styles.innerContainer}>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput
                                        value={this.props.new_password}
                                        onChange={this.props.changeHandler}
                                        type="password"
                                        className="form-control"
                                        name="new_password"
                                        label={<Text pid="changePass" tid="new_passInput" /> }
                                        required
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol>
                                        <MDBInput
                                        value={this.props.conf_password}
                                        onChange={this.props.changeHandler}
                                        type="password"
                                        className="form-control"
                                        name="conf_password"
                                        label={<Text pid="changePass" tid="conf_passInput" /> }
                                        required
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className={styles.submitContainer}>
                                <MDBCol>
                                    <MDBBtn className="w-100" type="submit">
                                        {<Text pid="changePass" tid="submitBtn" />}
                                    </MDBBtn>
                                </MDBCol>
                            </MDBRow>
                            </MDBContainer>
                        </form>
                    </MDBCard>
                </div>
            </div>
        );
    }
}