import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdbreact";
import ProductVarianPreview from "../ProductDetail/ProductVarianPreview";
import ProductDetailForm from "../ProductDetail/ProductDetailForm";
import SectionContainer from "../sectionContainer";
import ProductView from "../ProductHome/ProductView";

class ProductDetailView extends Component {
  componentDidMount() {
    const product_id = this.props.match.params.id;
    const sellerId = this.props.sellerprm.subDomainData.id;
    this.props.producthome.getProductDetail(sellerId, product_id);
    this.props.producthome.getProductAllCategory(sellerId);
    window.scrollTo(0,0)
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const sellerId = this.props.sellerprm.subDomainData.id;
      let product_id = this.props.match.params.id;
      this.props.producthome.getProductDetail(sellerId, product_id);
    }
  }

  render() {
    const sellerId = this.props.sellerprm.subDomainData.id;
    const isSeller = this.props.sellerprm.isSeller;
    const {
      category,
      product_detail,
      productDetailPhoto,
      addToChartFromPage,
    } = this.props.producthome;
    // console.log(this.props)
    return (
      <>
        {product_detail !== undefined ? (
          <MDBContainer style={{ paddingTop: "50px", minWidth: "1000px" }}>
            <MDBRow>
              <MDBCol size="6">
                <ProductVarianPreview photo={productDetailPhoto} />
              </MDBCol>
              <MDBCol size="6">
                <ProductDetailForm
                  sellerId={sellerId}
                  isSeller={isSeller}
                  name={product_detail.name}
                  product_detail={product_detail}
                  addToChartFromPage={addToChartFromPage}
                />
              </MDBCol>
            </MDBRow>
            <hr />
            <MDBRow>
              <MDBContainer style={{ minWidth: "1000px" }}>
                <MDBCard>
                  <MDBCardBody>
                    <MDBCardTitle>DESKRIPSI</MDBCardTitle>
                    <MDBCardText>{product_detail.description}</MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBContainer>
            </MDBRow>
            <br />
            <MDBRow>
              <MDBContainer style={{ minWidth: "1000px" }}>
                <SectionContainer header="Produk Lain">
                  {category ? (
                    <>
                      <MDBRow>
                        <ProductView
                          type="all"
                          product={
                            category.find(
                              (data) => data.id == product_detail.category_id
                            ).product
                          }
                        />
                      </MDBRow>
                      <Link to={"/category/" + product_detail.category_id}>
                        <MDBBtn className="w-100" color="deep-purple">
                          Lihat Lebih Banyak
                        </MDBBtn>
                      </Link>
                    </>
                  ) : (
                    ""
                  )}
                </SectionContainer>
              </MDBContainer>
            </MDBRow>
          </MDBContainer>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withRouter(ProductDetailView);
