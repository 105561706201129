import React, { Component } from "react";
import BankAccountFormModal from "../components/BankAccount/BankAccountFormModal";
import BankAccountView from "../components/BankAccount/BankAccountView";
import API from "../utils/API";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { MDBBtn, MDBIcon } from "mdbreact";

export default class BankAccount extends Component {
  state = {
    seller_bank_id: "",
    bank_id: "",
    no_rek: "",
    alias: "",

    bank_list: [],
    seller_bank_list: [],
    data: {},
    //other
    modal1: false,
    isEdit: false,
  };

  token = Cookies.get("access_token");
  config = {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + this.token,
    },
  };

  componentDidMount() {
    this.getBankAccountList();
  }

  //get Data
  getBankList = () => {
    API.get(`bank/list`, this.config)
      .then((res) => {
        if (res.data.data.bank) {
          let temp_bank = res.data.data.bank.map((data) => ({
            value: data.id.toString(),
            text: data.name,
            checked: data.id.toString() === this.state.bank_id,
          }));
          this.setState({ bank_list: temp_bank });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getBankAccountList = () => {
    const { sellerId } = this.props;
    API.get(`bank/${sellerId}`, this.config)
      .then((res) => {
        if (res.data.data.seller_bank) {
          let listDataTemp = res.data.data.seller_bank;
          let dataTableTemp = {
            columns: [
              {
                label: "Rekening",
                field: "bank",
                sort: "asc",
                minimal: 'lg'
              },
              {
                label: "Atas Nama",
                field: "name",
                sort: "asc",
                minimal: 'lg'
              },
              {
                label: "Primary",
                field: "primary",
                sort: "asc",
                minimal: 'lg'
              },
              {
                label: "Action",
                field: "action",          
              },
            ],
            rows: listDataTemp.map((data) => ({
              bank: (
                <>
                  <div className="font-weight-bold">{data.bank.name}</div>
                  <div>{`No. Rek ${data.no_rek}`}</div>
                </>
              ),
              name: data.alias,
              primary: parseInt(data.is_primary) === 1 ? "Utama" : "",
              action: (
                <>
                  <MDBBtn
                    size="sm"
                    color="primary"
                    onClick={() => this.editBankAccount(data)}
                  >
                    <MDBIcon icon="edit" className="mr-1" />
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color="danger"
                    onClick={() => this.deleteBankAccount(data.id)}
                  >
                    <MDBIcon icon="trash-alt" className="mr-1" />
                  </MDBBtn>
                  {parseInt(data.is_primary) === 0 ? (
                    <>
                      <br />
                      <MDBBtn
                        size="sm"
                        color="success"
                        onClick={() => this.bePrimaryBankAccount(data.id)}
                      >
                        <MDBIcon icon="warehouse" className="mr-1" />
                        Jadikan Utama
                      </MDBBtn>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ),
            })),
          };
          this.setState({
            seller_bank_list: listDataTemp,
            data: dataTableTemp,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handle function
  handleOnChangeBank = (event) => {
    this.setState({ bank_id: event[0] });
  };

  changeHandler = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    const { seller_bank_id, bank_id, alias, no_rek, isEdit } = this.state;
    const { sellerId } = this.props;

    const sent_data = new FormData();
    sent_data.append("seller_bank_id", seller_bank_id);
    sent_data.append("bank_id", bank_id);
    sent_data.append("alias", alias);
    sent_data.append("no_rek", no_rek);

    if (bank_id !== "" && alias !== "" && no_rek !== "") {
      API.post(`bank/${sellerId}`, sent_data, this.config)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: this.state.isEdit
              ? "Rekening Berhasil diperbaharui"
              : "Rekening Berhasil ditambah",
          }).then(() => {
            this.popupModal("CLOSE", "1");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //modification function
  editBankAccount = (data) => {
    console.log(data);
    this.setState({
      seller_bank_id: data.id,
      bank_id: data.bank_id,
      no_rek: data.no_rek,
      alias: data.alias,
    });
    this.popupModal("UPDATE", "1");
  };

  deleteBankAccount = (seller_bank_id) => {
    Swal.fire({
      title: "Anda yakin akan menghapus rekening ini?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((res) => {
      if (res.value) {
        const { sellerId } = this.props;
        API.delete(`bank/${sellerId}/${seller_bank_id}`, this.config)
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Rekening telah berhasil dihapus",
            }).then((res) => {
              this.getBankAccountList();
            });
          })
          .catch((error) => {
            this.getErrorApi(error);
          });
      } else {
        Swal.close();
      }
    });
  };

  bePrimaryBankAccount = (seller_bank_id) => {
    Swal.fire({
      title: "Anda yakin jadikan rekening ini sebagai rekening utama?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((res) => {
      if (res.value) {
        const { sellerId } = this.props;
        API.put(
          `bank/set_primary/${sellerId}/${seller_bank_id}`,
          null,
          this.config
        )
          .then((res) => {
            Swal.fire({
              icon: "success",
              text: "Rekening utama anda telah dirubah",
            }).then(() => {
              window.location.reload();
            });
          })
          .catch((error) => {});
      } else {
        Swal.close();
      }
    });
  };

  //modal function
  popupModal = (act, no, from = false) => {
    let modal = "modal" + no;
    if (act === "CLOSE") {
      this.setState(
        {
          [modal]: !this.state[modal],
          isEdit: false,
          no_rek: "",
          bank_id: "",
          alias: "",
          seller_bank_id: "",
        },
        () => {
          this.getBankAccountList();
        }
      );
    } else if (act === "ADD") {
      this.getBankList();
      this.setState({
        [modal]: !this.state[modal],
        isEdit: false,
      });
    } else if (act === "UPDATE") {
      this.getBankList();
      this.setState({ [modal]: !this.state[modal], isEdit: true });
    }
  };

  render() {
    const { sellerId } = this.props;
    return (
      <>
        <BankAccountFormModal
          {...this.state}
          popupModal={this.popupModal}
          changeHandler={this.changeHandler}
          onSubmitHandler={this.onSubmitHandler}
          handleOnChangeBank={this.handleOnChangeBank}
        />
        <BankAccountView {...this.state} popupModal={this.popupModal} />
      </>
    );
  }
}
