import React, { Component } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { MDBCol, MDBContainer, MDBFileInput, MDBRow } from "mdbreact";
import { SketchPicker } from "react-color";

export default class AboutFormView extends Component {
  render() {
    const props = this.props;
    const {
      changeTextHandler,
      changeColorHandler,
      changeImageHandler,
    } = this.props;
    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol size="3">
            <p>Background Color :</p>
            <h3
              style={{
                borderStyle: "solid",
                textAlign: "center",
                backgroundColor:props.background_color
              }}
            >
              {props.background_color}
            </h3>
            <SketchPicker
              color={props.background_color}
              onChangeComplete={changeColorHandler}
            />
          </MDBCol>
          <MDBCol>
            <MDBRow>
              <MDBCol>
                <div>{"Header Image :"}</div>
                <div hidden={props.header_img_prev === ""}>
                  <img
                    className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                    src={props.header_img_prev}
                    style={{ maxHeight: "150px" }}
                  />
                </div>
                <MDBFileInput
                  getValue={(e) => changeImageHandler(e, "header")}
                />
              </MDBCol>
              <MDBCol>
                <div>{"Company Image :"}</div>
                <div hidden={props.company_img_prev === ""}>
                  <img
                    className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                    src={props.company_img_prev}
                    style={{ maxHeight: "150px" }}
                  />
                </div>
                <MDBFileInput
                  getValue={(e) => changeImageHandler(e, "company")}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <div>{"Product Image :"}</div>
                <div hidden={props.product_img_prev === ""}>
                  <img
                    className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                    src={props.product_img_prev}
                    style={{ maxHeight: "150px" }}
                  />
                </div>
                <MDBFileInput
                  getValue={(e) => changeImageHandler(e, "product")}
                />
              </MDBCol>
              <MDBCol>
                <div>{"Other Image :"}</div>
                <div hidden={props.other_img_prev === ""}>
                  <img
                    className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                    src={props.other_img_prev}
                    style={{ maxHeight: "150px" }}
                  />
                </div>
                <MDBFileInput
                  getValue={(e) => changeImageHandler(e, "other")}
                />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <hr />
        <p>About Company :</p>
        <Editor
          apiKey="uiufc92ypcz3slpvjw31kxplkjk4dab3au761a8n1ycf65kq"
          value={props.company_txt}
          init={{
            height: 300,
            menubar: false,
            resize: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic underline link | fontselect forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
          }}
          onEditorChange={(event, editor) =>
            changeTextHandler(event, editor, "company")
          }
        />
        <hr />
        <p>About Product :</p>
        <Editor
          apiKey="uiufc92ypcz3slpvjw31kxplkjk4dab3au761a8n1ycf65kq"
          value={props.product_txt}
          init={{
            height: 300,
            menubar: false,
            resize: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic underline link | fontselect forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
          }}
          onEditorChange={(event, editor) =>
            changeTextHandler(event, editor, "product")
          }
        />
        <hr />
        <p>About Other :</p>
        <Editor
          apiKey="uiufc92ypcz3slpvjw31kxplkjk4dab3au761a8n1ycf65kq"
          value={props.other_txt}
          init={{
            height: 300,
            menubar: false,
            resize: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            toolbar:
              "undo redo | formatselect | bold italic underline link | fontselect forecolor backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent",
          }}
          onEditorChange={(event, editor) =>
            changeTextHandler(event, editor, "other")
          }
        />
        <hr />
      </MDBContainer>
    );
  }
}
