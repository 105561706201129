import React, { Component } from "react";
import SellerPrmContext from "../context/SellerPrmContext";
import { CartContext } from "../context/CartProvider";
import { ShipmentContext } from "../context/ShipmentProvider";
import ShipmentView from "../components/ShipmentView/ShipmentView";

export default class Shipment extends Component {
  render() {
    return (
      <SellerPrmContext.Consumer>
        {(sellerprm) => (
          <ShipmentContext.Consumer>
            {(shipmentprm) => (
              <ShipmentView
                sellerprm={sellerprm}
                shipmentprm={shipmentprm}
              />
            )}
          </ShipmentContext.Consumer>
        )}
      </SellerPrmContext.Consumer>
    );
  }
}
