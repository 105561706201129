import React, { Component } from "react";
import UserLocationView from "./UserLocationView";
import UserLocationFormModal from './UserLocationFormModal'

export default class UserLocationMain extends Component {
  componentDidMount() {
    this.props.user_location_prm.listUserLocation();
  }

  render() {
    const props = this.props.user_location_prm;
    return (
      <>
        <UserLocationFormModal {...props} />
        <UserLocationView
          popupModal={props.popupModal}
          data={props.dataTable}
        />
      </>
    );
  }
}
