import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBBtn } from "mdbreact";
import { Text } from "../../context/Language";

export default class ProfileViewUser extends Component {
  componentDidMount() {}

  render() {
    let { isEdit, isEditChange, updateUserProfile } = this.props;
    return (
      <MDBContainer style={{ minWidth: "1000px" }}>
        <form
          className="needs-validation"
          onSubmit={updateUserProfile}
          noValidate
        >
          <MDBRow>
            <MDBCol>
              <MDBInput
                value={this.props.data.name}
                onChange={this.props.changeHandler}
                type="text"
                className="form-control"
                id="name"
                name="name"
                label={<Text pid="profileUser" tid="name" />}
                required
                disabled={isEdit}
              />
              <MDBInput
                value={this.props.data.email}
                onChange={this.props.changeHandler}
                type="email"
                className="form-control"
                id="email"
                name="email"
                label={<Text pid="profileUser" tid="email" />}
                required
                disabled={isEdit}
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol size="6" hidden={isEdit ? true : false}>
              <MDBBtn color="deep-purple" className="w-100" type="submit">
                {<Text pid="profileUser" tid="submitBtn" />}
              </MDBBtn>
            </MDBCol>
            <MDBCol size="6" hidden={isEdit ? false : true}>
              <MDBBtn
                color="deep-purple"
                className="w-100"
                onClick={() => isEditChange()}
              >
                {<Text pid="profileUser" tid="editBtn" />}
              </MDBBtn>
            </MDBCol>
            <MDBCol size="6">
              <MDBBtn
                color="deep-purple"
                className="w-100"
                onClick={() => this.props.popupModal("1")}
              >
                {<Text pid="profileUser" tid="changePassBtn" />}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </form>
      </MDBContainer>
    );
  }
}
