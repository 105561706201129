import React, { Component } from "react";
import API from "../../utils/API";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import ProfileViewSeller from "./ProfileViewSeller";
import SectionContainer from "../sectionContainer";
import { Text } from "../../context/Language";
import { MDBContainer } from "mdbreact";
import { withRouter } from "react-router-dom";

class ProfileSellerMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      user_id: "",
      facebook: "",
      twitter: "",
      instagram: "",
      nameWeb: "",

      //domain
      old_subDomain: "",
      new_subDomain: "",
      old_domain: "",
      new_domain: "",

      //toko
      old_logoToko: "",
      new_logoToko: "",
      preview_logo: "",
      old_bannerToko: "",
      new_bannerToko: "",
      preview_banner: "",
      template_id: "",

      //param
      template_list: [],
      template_preview: "",
      default_selected_logo: "",
      default_selected_banner: "",
      isEdit: true,
      is_default_logo: false,
      is_default_banner: false,
    };
    this._isMounted = false;
    this.config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + Cookies.get("access_token"),
      },
    };

    this.config2 = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };
  }

  //init
  componentDidMount() {
    this._isMounted = true;
    this.getProfile();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //handler
  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  changeImageHandler = (event, type) => {
    if (event[0]) {
      const param = "new_" + type + "Toko";
      const preview = "preview_" + type;
      this.setState({
        [param]: event[0],
        [preview]: URL.createObjectURL(event[0]),
      });
    }
  };

  isDefaultHandler = (type) => {
    const param = "is_default_" + type;
    this.setState({ [param]: !this.state[param] });
  };

  isEditChange = (action) => {
    this.setState({ isEdit: !this.state.isEdit });
    if (action === "cancel") {
      window.location.reload();
    }
  };

  selectTemplateHandler = (event) => {
    let tempSelected;
    tempSelected = this.state.template.find((item) => item.value === event[0]);
    // console.log(event[0])
    if (event[0]) {
      this.setState({
        template_id: event[0],
        template_preview: tempSelected.view_1,
        default_selected_banner: tempSelected.banner,
        default_selected_logo: tempSelected.logo,
      });
    }
  };

  //getData
  getProfile = () => {
    let id = this.props.sellerprm.subDomainData.id;
    API.get(`auth/user/${id}`, this.config)
      .then((res) => {
        console.log(res);
        let seller = res.data.data.seller;
        if (seller) {
          let subdomain = "" + seller.subdomain;
          subdomain = subdomain.substring(0, subdomain.indexOf("."));
          this.setState(
            {
              id: seller.id,
              user_id: seller.user_id ? seller.user_id : "",
              facebook: seller.facebook ? seller.facebook : "",
              twitter: seller.twitter ? seller.twitter : "",
              instagram: seller.instagram ? seller.instagram : "",
              nameWeb: seller.name_web ? seller.name_web : "",

              //domain
              old_subDomain: subdomain,
              new_subDomain: subdomain,
              old_domain: seller.domain === null ? "" : seller.domain,
              new_domain: seller.domain === null ? "" : seller.domain,

              //toko
              old_logoToko: seller.logo,
              new_logoToko: seller.logo,
              preview_logo: seller.logo,
              old_bannerToko: seller.banner,
              new_bannerToko: seller.banner,
              preview_banner: seller.banner,
              template_id: seller.template_id,

              //other
              renderLoaderSub: false,
              renderLoaderDomain: false,
              isSubDomainChecked: false,
              isDomainChecked: false,
              isAvailableSubDomain: "",
              isAvailableDomain: "",
            },
            () => {
              console.log(this.state);
              this.getTemplateList();
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTemplateList = () => {
    API.get("/prm/template", this.config2)
      .then((res) => {
        if (res.data.code !== "200") {
          alert(res.data.errors);
        } else {
          // console.log(res.data.data);
          let default_logo = res.data.data.logo;
          let templateTemp = res.data.data.template.map((template) => ({
            checked: this.state.template_id == template.id ? true : false,
            text: template.name,
            value: template.id.toString(),
            banner: template.banner,
            logo: default_logo,
            view_1: template.view_1,
          }));
          let temp_prev = templateTemp[this.state.template_id].view_1;
          if (this._isMounted) {
            this.setState({
              template: templateTemp,
              template_preview: temp_prev,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //check function
  checkDomainAvailability = (domain, type) => {
    let link;
    if (type === "sub") {
      this.setState({ renderLoaderSub: true });
      link = domain + ".1toko.com";
    } else {
      this.setState({ renderLoaderDomain: true });
      link = domain;
    }

    API.post("/auth/check_sub_domain", { web: link }, this.config2)
      .then((res) => {
        let temp = res.data.data;
        if (!Array.isArray(temp) && type === "sub") {
          this.setState({
            isAvailableSubDomain: false,
            isSubDomainChecked: true,
            renderLoaderSub: false,
          });
        } else if (!Array.isArray(temp) && type === "domain") {
          this.setState({
            isAvailableDomain: false,
            isDomainChecked: true,
            renderLoaderDomain: false,
          });
        } else if (Array.isArray(temp) && type === "sub") {
          this.setState({
            isAvailableSubDomain: true,
            isSubDomainChecked: true,
            renderLoaderSub: false,
          });
        } else if (Array.isArray(temp) && type === "domain") {
          this.setState({
            isAvailableDomain: true,
            isDomainChecked: true,
            renderLoaderDomain: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ renderLoader: false });
        console.log(err);
      });
  };

  //modifiData
  updateSellerProfile = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    const data = this.state;
    const sent_data = new FormData();
    sent_data.append("id", data.id);
    sent_data.append("name_web", data.nameWeb);
    sent_data.append("domain", data.new_domain);
    sent_data.append("subdomain", `${data.new_subDomain}.1toko.com`);
    sent_data.append("template_id", data.template_id);
    if (typeof data.new_logoToko === "object") {
      sent_data.append("logo", data.new_logoToko);
    } else {
      sent_data.append(
        "link_logo",
        data.is_default_logo ? data.default_selected_logo : data.old_logoToko
      );
    }
    if (typeof data.new_bannerToko === "object") {
      sent_data.append("banner", data.new_bannerToko);
    } else {
      sent_data.append(
        "link_banner",
        data.is_default_banner
          ? data.default_selected_banner
          : data.old_bannerToko
      );
    }
    sent_data.append("facebook", data.facebook);
    sent_data.append("twitter", data.twitter);
    sent_data.append("instagram", data.instagram);
    console.log(data);
    if (data.new_subDomain !== "") {
      API.post(`/auth/update_seller`, sent_data, this.config)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Data berhasil terupdate",
          }).then(() => {
            // this.isEditChange();
            window.location.reload();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    return (
      <MDBContainer style={{ paddingTop: "4rem", minWidth: "1000px" }}>
        <SectionContainer header={<Text pid="profileSeller" tid="title" />}>
          <ProfileViewSeller
            {...this.state}
            isEditChange={this.isEditChange}
            isDefaultHandler={this.isDefaultHandler}
            changeHandler={this.changeHandler}
            updateSellerProfile={this.updateSellerProfile}
            checkDomainAvailability={this.checkDomainAvailability}
            selectTemplateHandler={this.selectTemplateHandler}
            // selectDetailProductPhoto={this.selectDetailProductPhoto}
            changeImageHandler={this.changeImageHandler}
          />
        </SectionContainer>
      </MDBContainer>
    );
  }
}

export default withRouter(ProfileSellerMain);
