import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader } from "mdbreact";
import React, { Component } from "react";
import AboutView from "./AboutView";

export default class AboutPreviewModal extends Component {
  render() {
    const props = this.props;
    return (
      <MDBContainer className="overflow-auto" style={{ maxHeight: "500px" }}>
        <MDBModal
          size="lg"
          isOpen={this.props.modal1}
          toggle={() => this.props.popupModal("1")}
        >
          <MDBModalHeader toggle={() => this.props.popupModal("1")}>
            PREVIEW ABOUT
          </MDBModalHeader>
          <MDBModalBody>
            <AboutView {...this.props} />
          </MDBModalBody>
        </MDBModal>
      </MDBContainer>
    );
  }
}
