import React, { Component } from "react";
import SellerProductsFormMain from "../components/SellerProducts/SellerProductsFormMain";
import SellerProductsContext from "../context/SellerProductsContext";
// import SellerCategoryContext from "../context/SellerCategoryContext";
import SellerLocationContext from "../context/SellerLocationContext";
import SellerPrmContext from "../context/SellerPrmContext";

export default class SellerProductsForm extends Component {
  render() {
    return (
      <>
        <SellerPrmContext.Consumer>
          {(sellerPrm) => (
            <SellerLocationContext.Consumer>
              {(sellerLocation) => (
                <SellerProductsContext.Consumer>
                  {(sellerProducts) => (
                    <SellerProductsFormMain
                      sellerLocation={sellerLocation}
                      sellerProducts={sellerProducts}
                      sellerPrm={sellerPrm}
                    />
                  )}
                </SellerProductsContext.Consumer>
              )}
            </SellerLocationContext.Consumer>
          )}
        </SellerPrmContext.Consumer>
      </>
    );
  }
}
