import React, { Component } from "react";
import API from "../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import moment from "moment";

import SellerPrmContext from "./SellerPrmContext";

class SellerPrmProvider extends Component {
  state = {
    link: "",
    loginData: {}, //login
    subDomainData: {}, //func checkUserDomain
    isShopDomain: true,
    isSeller: false,
    isLoading: false,
    isError: false,
    isLoginState: false,
    isLogFromCookies: false,
    dataUser: {},
  };

  getHostName = (link) => {
    this.setState({ link }, ()=> this.checkUserDomain());
  };

  checkUserDomain = () => {
    // this.setState({ isLoading: true });
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.post("/auth/check_sub_domain", { web: this.state.link }, config)
      .then((res) => {
        if (res.data.data) {
          // const expired = moment(res.data.data.expires_at).toDate();
          // Cookies.set("qqq", res.data.data.seller.user_id, {
          //   expires: expired,
          // });
          this.setState(
            {
              subDomainData: res.data.data.seller,
              isShopDomain: true,
            },
            () => this.checkDataUser()
          );
        } else {
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        if (err.response) {
          let errmessage = "";
          let keys = Object.keys(err.response.data.errors);
          for (let i = 0; i < keys.length; i++) {
            errmessage +=
              keys[i] + " " + err.response.data.errors[keys[i]][0] + "<br/>";
          }
          Swal.fire({
            icon: "error",
            html: errmessage,
          });
        }
      });
  };

  // check data if seller
  checkDataUser = () => {
    if (Cookies.get("access_token")) {
      let token = Cookies.get("access_token");

      const config = {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + token,
        },
      };

      API.get(`/auth/user/${parseInt(Cookies.get("logged"))}`, config)
        .then((res) => {
          if (res.data.data) {
            this.setState(
              {
                dataUser: res.data.data ? res.data.data.user : "",
              },
              () => {
                this.checkSellerLogin();
              }
            );
          }
        })
        .catch((err) => {
          // if (err.response) {
          //   let errmessage = "";
          //   let keys = Object.keys(err.response.data.errors);
          //   for (let i = 0; i < keys.length; i++) {
          //     errmessage +=
          //       keys[i] + " " + err.response.data.errors[keys[i]][0] + "<br/>";
          //   }
          //   Swal.fire({
          //     icon: "error",
          //     html: errmessage,
          //   });
          // }
        });
    } else {
      this.checkSellerLogin();
    }
  };

  //check kalau yg login seller
  checkSellerLogin = () => {
    if (Cookies.get("access_token")) {
      //yg log in seller sama dengan website
      // console.log(this.state.subDomainData.id);
      // console.log(Cookies.get("logged"));
      if (
        parseInt(Cookies.get("logged")) == parseInt(this.state.subDomainData.id)
      ) {
        this.setState({ isSeller: true, isLoading: false, isLoginState: true });
      } else {
        this.setState({
          isSeller: false,
          isLoading: false,
          isLoginState: true,
        });
      }
    }
  };

  render() {
    return (
      <SellerPrmContext.Provider
        value={{
          ...this.state,
          web_link: this.state.link,
          checkUserDomain: this.checkUserDomain,
          checkDataUser: this.checkDataUser,
          getHostName: this.getHostName
        }}
      >
        {this.props.children}
      </SellerPrmContext.Provider>
    );
  }
}

export default SellerPrmProvider;
