import React, { Component } from "react";
import { deviceDetect, isMobile } from "react-device-detect";
import { Route } from "react-router-dom";
import TemplateSwitcher from "../components/TemplateSwitcher/TemplateSwitcher";

export default class RedirectPlatform extends Component {
  state = {
    isMobile: false,
    hostname: window.location.hostname,
  };

  render() {
    if (this.state.hostname)
      if (isMobile) {
        return (
          <Route
            path="/"
            component={() => {
              // window.location = `https://${this.state.hostname}.1toko.com`;
              window.location = `https://mobile.1toko.com`;
              return null;
            }}
          />
        );
      } else {
        return <TemplateSwitcher />;
      }
  }
}
