import React, { createContext, Component } from "react";
//import for provider
import API from "../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Axios from "axios";

//Context
export const ShipmentContext = createContext();

export default class ShipmentProvider extends Component {
  state = {
    //cart
    cart_list: undefined,
    total_price: 0,
    total_product: 0,
    total_shipment_service: 0,
    //location
    user_location_list: undefined,
    selected_location: undefined,
    //other
    is_ready: false,
    modal1: false,
  };

  //function
  createConfig = (token) => {
    let config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    return config;
  };

  setInit = async (sellerId) => {
    //location
    let user_location_list = await this.getUserLocationList(sellerId);
    let temp_select_loc = user_location_list.filter(
      (data) => data.is_primary === "1"
    )[0];
    //cart
    let cart_list = await this.getCartList(sellerId);
    this.calculateTotal(cart_list);
    cart_list = await this.getCourierList(cart_list, temp_select_loc);
    this.setState({
      user_location_list: user_location_list,
      selected_location: temp_select_loc,
      cart_list: cart_list,
    });
  };

  //get data
  getUserLocationList = async (sellerId) => {
    let temp_loc = await API.get(
      "/user_location",
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        if (res.data.data) {
          let listDataTemp = res.data.data.user_location.map((list) => ({
            id: list.id,
            user_id: list.user_id,
            alias: list.alias,
            name: list.name,
            hp: list.hp,
            address: list.address,
            zip_id: {
              label:
                list.zip.province +
                ", " +
                list.zip.sub_province +
                ", " +
                list.zip.district +
                ", " +
                list.zip.sub_district +
                ", " +
                list.zip.zip,
              value: list.zip,
            },
            latitude: list.latitude,
            longitude: list.longitude,
            is_primary: list.is_primary,
            created_at: list.created_at,
            updated_at: list.updated_at,
            text: list.alias,
            value: list.id,
          }));
          return listDataTemp;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return temp_loc;
  };

  getCourierList = async (cart_list, user_loc) => {
    let res_array = [];
    cart_list.map((cart) => {
      const data = new FormData();
      data.append("cart_id", cart.cart_id);
      data.append("origin", cart.seller_location.zip.id);
      data.append("destination", user_loc.zip_id.value.id);
      data.append("cart_weight", cart.total_weight);
      data.append("cart_price", cart.total_price);
      res_array.push(
        API.post(
          `/prm/courier/check-price`,
          data,
          this.createConfig(Cookies.get("access_token"))
        )
      );
    });
    let res = await Axios.all(res_array);
    let new_list = [];
    res.map((res, index) => {
      if (res.data.data) {
        if (parseInt(res.data.code) === 200) {
          let temp_c = [];
          let temp_s = [];
          res.data.data.courier.map((data,index) => {
            if(!data.courier_data.code){
              temp_c.push({ value: index.toString(), text: data.courier_name });
              temp_s.push({ value: index.toString(),...data.courier_data });
            } 
          });
          new_list.push({
            ...cart_list[index],
            courier_list: temp_c,
            service_list: temp_s,
          });
        }
      }
    });
    return new_list;
  };

  getCartList = async (sellerId) => {
    let temp_cart = await API.get(
      `cart/${sellerId}`,
      this.createConfig(Cookies.get("access_token"))
    )
      .then((res) => {
        let temp_cart_list = res.data.data.cart;
        if (temp_cart_list.length > 0) {
          temp_cart_list = temp_cart_list.map((cart, index) => {
            let temp_detail = cart.dtl_cart.map((data) => ({
              detail_id: data.id,
              product_id: data.dtl_product.id,
              product_name: data.group_product.name,
              varian_name: data.dtl_product.name,
              price: parseInt(data.dtl_product.price),
              quantity: parseInt(data.qty),
              note: data.note,
              stock: parseInt(data.dtl_product.stock),
              weight: parseInt(data.dtl_product.weight),
              sub_total: parseInt(data.dtl_product.price) * parseInt(data.qty),
              img: data.group_product.thumbnail.link,
              is_selected: data.checked,
            }));

            let total = this.calculateCartTotal(temp_detail);
            return {
              cart_id: cart.id,
              seller_location: cart.seller_location,
              cart_detail: temp_detail,
              total_price: total.subtotal_price,
              total_weight: total.subtotal_weight,
              total_product: total.subtotal_product,
              //shipment
              courier_is_selected: false,
              service_is_selected: false,
              selected_courier: undefined,
              selected_pakage_service: undefined,
              service_price: 0,
            };
          });
          return temp_cart_list;
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return temp_cart;
  };

  //handel
  changeUserLocationHandler = (index) => {
    this.setState({ selected_location: this.state.user_location_list[index] });
  };

  changeCourierHandler = async (event) => {
    console.log(event[0]);
  };

  changeServiceHandler = (event, payload) => {};

  popupModal = (act, no) => {
    let modal = "modal" + no;
    if (act === "CLOSE") {
      this.setState({ [modal]: !this.state[modal] });
    } else if (act === "SHOW") {
      this.setState({ [modal]: !this.state[modal] });
    }
  };

  //cart list function
  calculateCartTotal = (list) => {
    let temp_price =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.sub_total);
            }, 0)
        : 0;
    let temp_product =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              return accumulator + parseInt(currentValue.quantity);
            }, 0)
        : 0;
    let temp_weight =
      list.length > 0
        ? list
            .filter((data) => parseInt(data.is_selected) === 1)
            .reduce((accumulator, currentValue) => {
              let weight =
                parseInt(currentValue.weight) * parseInt(currentValue.quantity);
              return accumulator + weight;
            }, 0)
        : 0;
    return {
      subtotal_price: temp_price,
      subtotal_product: temp_product,
      subtotal_weight: temp_weight,
    };
  };

  calculateTotal = (list) => {
    let temp_price = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.total_price);
    }, 0);
    let temp_product = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.total_product);
    }, 0);
    let temp_service = list.reduce((accumulator, currentValue) => {
      return accumulator + parseInt(currentValue.service_price);
    }, 0);

    this.setState({
      total_price: temp_price,
      total_item: temp_product,
      total_shipment_service: temp_service,
    });
  };

  checkIsReady = () => {
    let ready = this.state.cart_list.find(
      (data) => data.service_is_selected === false
    );
    this.setState({ is_ready: !ready ? true : false });
  };

  render() {
    return (
      <ShipmentContext.Provider
        value={{
          ...this.state,
          setInit: this.setInit,
          //handler
          popupModal: this.popupModal,
          changeCourierHandler: this.changeCourierHandler,
          changeServiceHandler: this.changeServiceHandler,
          changeUserLocationHandler: this.changeUserLocationHandler,
        }}
      >
        {this.props.children}
      </ShipmentContext.Provider>
    );
  }
}
