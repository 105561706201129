import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// context
import RedirectPlatform from "../src/components/RedirectPlatform";

import { LanguageProvider } from "./context/Language";
import SellerLocationProvider from "./context/SellerLocationProvider";
import SellerCategoryProvider from "./context/SellerCategoryProvider";
import SellerProductsProvider from "./context/SellerProductsProvider";
import ProductHomeProvider from "./context/ProductHomeProvider";
import CartProvider from "./context/CartProvider";
import ShipmentProvider from "./context/ShipmentProvider";
import { isMobile } from "react-device-detect";
import TemplateSwitcher from "./components/TemplateSwitcher/TemplateSwitcher";
import SellerPrmContext from "./context/SellerPrmContext";
// redux
// import { Provider } from "react-redux";
// import store from "./store";

class App extends Component {
  componentDidMount() {
    const link = window.location.hostname;
    // const link = "coolmusic.1toko.com"; //template 1
    // const link = "tokosekolah.1toko.com";//template 1
    // const link = "abc.1toko.com"; //template 2
    this.context.getHostName(link);
  }

  render() {
    if (isMobile) {
      return <div>MOBILE</div>;
    } else {
      return (
        <ShipmentProvider>
          <CartProvider>
            <ProductHomeProvider>
              <SellerProductsProvider>
                <SellerCategoryProvider>
                  <SellerLocationProvider>
                    <LanguageProvider>
                      <TemplateSwitcher />
                      {/* <RedirectPlatform/> */}
                    </LanguageProvider>
                  </SellerLocationProvider>
                </SellerCategoryProvider>
              </SellerProductsProvider>
            </ProductHomeProvider>
          </CartProvider>
        </ShipmentProvider>
      );
    }
  }
}

App.contextType = SellerPrmContext;

export default withRouter(App);
