import React, { Component } from "react";
import API from "../utils/API";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

import SellerCategoryContext from "./SellerCategoryContext";
import { MDBBtn, MDBIcon } from "mdbreact";

class SellerCategoryProvider extends Component {
  state = {
    // state
    name: "",
    isDefaultIcon: false,
    selectedDefaultIcon: "",
    selectedIconCategory: "",
    // if only update name
    updateIconOriginal: "",
    //
    previewIconDefault: "",
    previewIcon: "",
    defaultIconList: [],
    sellerId: "",
    data: [],
    dataTable: {},
    modalState: false,
    updateSellerCategoryId: "",
    actionType: "",
    isLoading: false,
    isError: false,
  };

  modalHandler = (actionType, list) => {
    if (actionType === "ADD") {
      this.setState({
        updateSellerCategoryId: "",
        name: "",
        previewIconDefault: "",
        previewIcon: "",
        selectedDefaultIcon: "",
        selectedIconCategory: "",
        actionType,
        modalState: this.state.modalState ? false : true,
      });
    } else if (actionType === "UPDATE") {
      this.setState({
        updateSellerCategoryId: list.id,
        name: list.name,
        updateIconOriginal: list.img,
        previewIconDefault: "",
        previewIcon: "",
        selectedDefaultIcon: "",
        selectedIconCategory: "",
        actionType,
        modalState: this.state.modalState ? false : true,
      });
    } else if (actionType === "CLOSE") {
      this.setState({
        updateSellerCategoryId: "",
        name: "",
        previewIconDefault: "",
        previewIcon: "",
        selectedDefaultIcon: "",
        selectedIconCategory: "",
        actionType,
        modalState: this.state.modalState ? false : true,
      });
    }
  };

  checkDefaultIconHandler = () => {
    this.setState(
      {
        isDefaultIcon: !this.state.isDefaultIcon,
      },
      () => console.log(this.state.isDefaultIcon)
    );
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getDefaultCategoryIcon = () => {
    this.setState({ isLoading: true });
    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    API.get("/prm/category", config)
      .then((res) => {
        // console.log(res);
        if (parseInt(res.data.code) !== 200) {
          alert(res.data.errors);
        } else {
          let categoryTemp = res.data.data.category.map((category) => ({
            id: category.id,
            value: category.id.toString(),
            text: category.name,
            img: category.img,
          }));
          // if (this._isMounted) {
          this.setState({ defaultIconList: categoryTemp, isLoading: false });
          // }
        }
      })
      .catch((error) => {
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  selectDefaultIconHandler = (event) => {
    let tempSelected;
    tempSelected = this.state.defaultIconList.find(
      (item) => item.value === event[0]
    );
    if (event[0]) {
      this.setState(
        {
          selectedDefaultIcon: tempSelected.img,
          previewIconDefault: tempSelected.img,
          updateIconOriginal: "",
        },
        () => console.log(this.state.selectedDefaultIcon)
      );
    }
  };

  selectIconCategoryHandler = (event) => {
    if (event[0]) {
      this.setState(
        {
          selectedIconCategory: event[0],
          previewIcon: URL.createObjectURL(event[0]),
          updateIconOriginal: "",
        },
        () => console.log(this.state.selectedIconCategory)
      );
    }
  };

  listSellerCategoryActive = () => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    };

    const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_category/${sellerId}?active=1`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listData = res.data.data.category.map((list, idx) => ({
            //react-grid-dropdown
            label: list.name,
            backgroundImage: `url(${list.img})`,
            img: list.img,
            index: idx,
            //select
            text: list.name,
            value: list.id.toString()
          }));
          this.setState({
            data: listData,
            isLoading: false,
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  listSellerCategory = (sellerId) => {
    this.setState({ isLoading: true });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // ${this.state.sellerId}

    API.get(`/seller_category/${sellerId}`, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (res.data.data) {
          let listDataTemp = res.data.data.category.map((list) => ({
            seq: list.seq,
            id: list.id,
            seller_id: list.seller_id,
            name: list.name,
            img: list.img,
            is_active: list.is_active,
          }));
          let dataTableTemp = {
            columns: [
              {
                label: "No.",
                field: "seq",
                sort: "asc",
              },
              {
                label: "Nama",
                field: "name",
                sort: "asc",
              },
              {
                label: "Icon",
                field: "img",
                sort: "asc",
              },
              {
                label: "Kategori Aktif",
                field: "isActive",
                sort: "asc",
              },
              {
                label: "Action",
                field: "action",
              },
            ],
            rows: listDataTemp.map((list) => ({
              // SellerCategoryId: list.id,
              seq: list.seq,
              name: list.name,
              img: (
                <img
                  style={{ maxWidth: 128 }}
                  src={list.img}
                  alt={"icon" + list.id}
                />
              ),
              isActive:
                parseInt(list.is_active) === 1 ? "Aktif" : "Tidak Aktif",
              action: (
                <>
                  <>
                    <MDBBtn
                      size="sm"
                      color={parseInt(list.is_active) !== 1 ? "green" : "red"}
                      onClick={() =>
                        this.beActiveCategory(list.id, list.is_active, sellerId)
                      }
                    >
                      {parseInt(list.is_active) !== 1 ? (
                        <>
                          {" "}
                          <MDBIcon
                            icon="toggle-on"
                            className="mr-1"
                          /> Aktifkan{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <MDBIcon icon="toggle-off" className="mr-1" />{" "}
                          Nonaktifkan{" "}
                        </>
                      )}
                    </MDBBtn>
                    <br />
                  </>

                  <MDBBtn
                    size="sm"
                    color='primary'
                    onClick={() => this.modalHandler("UPDATE", list)}
                  >
                    <MDBIcon icon="edit" className="mr-1" /> Ubah
                  </MDBBtn>
                  {/* <MDBBtn
                    size="sm"
                    onClick={() =>
                      this.deleteSellerCategory(list.seller_id, list.id)
                    }
                  >
                    <MDBIcon icon="trash-alt" className="mr-1" /> Hapus
                  </MDBBtn> */}
                </>
              ),
            })),
          };
          this.setState(
            {
              // data: listDataForHeader,
              dataTable: dataTableTemp,
              isLoading: false,
            },
            () => console.log(this.state.data)
          );
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  storeSellerCategory = (sellerId) => {
    this.setState({ isLoading: true });

    //Swal loading
    Swal.fire({
      title: "Menambahkan...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    const data = new FormData();
    data.append("name", this.state.name);
    if (this.state.isDefaultIcon) {
      data.append("link_img", this.state.selectedDefaultIcon);
    } else {
      data.append("img", this.state.selectedIconCategory);
    }

    if (this.state.selectedDefaultIcon || this.state.selectedIconCategory) {
      API.post(`/seller_category/${sellerId}`, data, config)
        .then((res) => {
          this.setState({ isLoading: false });
          if (parseInt(res.data.code) !== 200) {
            console.log(res);
          } else {
            this.setState(
              {
                name: "",
                previewIcon: "",
                selectedIconCategory: "",
                previewIconDefault: "",
                selectedDefaultIcon: "",
                updateIconOriginal: "",
                isDefaultIcon: false,
              },
              () => {
                this.listSellerCategory(sellerId);
                this.modalHandler("CLOSE");
              }
            );
            Swal.fire({
              icon: "success",
              text: "Berhasil menambahkan kategori",
              onBeforeOpen: () => {
                Swal.hideLoading();
              },
            });
          }
        })
        .catch((error) => {
          Swal.disableLoading();
          if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            if (error.response) {
              let errmessage = "";
              let keys = Object.keys(error.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                errmessage +=
                  keys[i] +
                  " " +
                  error.response.data.errors[keys[i]][0] +
                  "<br/>";
              }
              Swal.fire({
                icon: "error",
                html: errmessage,
              });
            }
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log("Error", error.message);
          }
          console.log(error.config);
        });
    } else {
      Swal.fire({
        icon: "error",
        text: "Gambar harus diisi atau dipilih",
      });
    }
  };

  updateSellerCategory = (sellerId) => {
    this.setState({
      isLoading: true,
    });

    //Swal loading
    Swal.fire({
      title: "Mengubah...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const data = {
    //   updateSellerCategoryId: list.id,
    //     name: list.name,
    //     previewIcon: list.img,
    //     selectedIconCategory: list.img,
    // };

    // const sellerId = Cookies.get("qqq");
    // console.log(this.state.sellerId);

    console.log(this.state.selectedDefaultIcon);
    console.log(this.state.selectedIconCategory);

    const data = new FormData();
    data.append("name", this.state.name);
    if (this.state.isDefaultIcon) {
      data.append("link_img", this.state.selectedDefaultIcon);
    } else {
      data.append("img", this.state.selectedIconCategory);
    }
    if (!this.state.selectedDefaultIcon && !this.state.selectedIconCategory) {
      data.append("link_img", this.state.updateIconOriginal);
    }
    data.append("id", this.state.updateSellerCategoryId);

    API.post(`/seller_category/${sellerId}`, data, config)
      .then((res) => {
        this.setState({ isLoading: false });
        if (parseInt(res.data.code) !== 200) {
        } else {
          this.listSellerCategory(sellerId);
          this.modalHandler("CLOSE");
          // this.setState(
          //   {
          //     name: "",
          //     previewIcon: "",
          //     selectedIconCategory: "",
          //     previewIconDefault: "",
          //     selectedDefaultIcon: "",
          //     updateIconOriginal: "",
          //     updateSellerCategoryId: "",
          //     isDefaultIcon: false,
          //   },
          //   () => {
          //     this.listSellerCategory();
          //     this.modalHandler("CLOSE");
          //   }
          // );
          Swal.fire({
            icon: "success",
            text: "Kategori telah berhasil diupdate",
            onBeforeOpen: () => {
              Swal.hideLoading();
            },
          });
        }
      })
      .catch((error) => {
        Swal.disableLoading();
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          if (error.response) {
            let errmessage = "";
            let keys = Object.keys(error.response.data.errors);
            for (let i = 0; i < keys.length; i++) {
              errmessage +=
                keys[i] +
                " " +
                error.response.data.errors[keys[i]][0] +
                "<br/>";
            }
            Swal.fire({
              icon: "error",
              html: errmessage,
            });
          }
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  };

  // deleteSellerCategory = (sellerId, categoryId) => {
  //   let token = Cookies.get("access_token");

  //   const config = {
  //     headers: {
  //       "X-Requested-With": "XMLHttpRequest",
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   Swal.fire({
  //     title: "Anda yakin akan menghapus kategori ini?",
  //     icon: "warning",
  //     cancelButtonText: "Tidak",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Ya",
  //   }).then((result) => {
  //     if (result.value) {
  //       return API.delete(`/seller_location/${sellerId}/${categoryId}`, config)
  //         .then((res) => {
  //           if (parseInt(res.data.code) !== 200) {
  //             console.log(res.data);
  //           } else {
  //             Swal.fire({
  //               title: "Berhasil",
  //               text: "Alamat telah berhasil dihapus",
  //               type: "success",
  //               showConfirmButton: true,
  //               confirmButtonText: "Tutup",
  //             }).then(() => this.listSellerCategory());
  //           }
  //         })
  //         .catch((error) => {
  //           Swal.disableLoading();
  //           if (error.response) {
  //             /*
  //              * The request was made and the server responded with a
  //              * status code that falls out of the range of 2xx
  //              */
  //             console.log(error.response.data);
  //             console.log(error.response.status);
  //             console.log(error.response.headers);
  //             if (error.response) {
  //               let errmessage = "";
  //               let keys = Object.keys(error.response.data.errors);
  //               for (let i = 0; i < keys.length; i++) {
  //                 errmessage +=
  //                   keys[i] +
  //                   " " +
  //                   error.response.data.errors[keys[i]][0] +
  //                   "<br/>";
  //               }
  //               Swal.fire({
  //                 icon: "error",
  //                 html: errmessage,
  //               });
  //             }
  //           } else if (error.request) {
  //             /*
  //              * The request was made but no response was received, `error.request`
  //              * is an instance of XMLHttpRequest in the browser and an instance
  //              * of http.ClientRequest in Node.js
  //              */
  //             console.log(error.request);
  //           } else {
  //             // Something happened in setting up the request and triggered an Error
  //             console.log("Error", error.message);
  //           }
  //           console.log(error.config);
  //         });
  //     } else {
  //       Swal.close();
  //     }
  //   });
  // };

  beActiveCategory = (categoryId, statusCategory, sellerId) => {
    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };

    // const sellerId = Cookies.get("qqq");

    const data = {
      is_active: parseInt(statusCategory) === 1 ? "0" : "1",
    };

    Swal.fire({
      title: parseInt(statusCategory) === 1 ? "Nonaktifkan?" : "Aktifkan?",
      icon: "warning",
      cancelButtonText: "Tidak",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya",
    }).then((result) => {
      if (result.value) {
        return API.put(
          `/seller_category/is_active/${sellerId}/${categoryId}`,
          data,
          config
        )
          .then((res) => {
            Swal.fire({
              title: "Berhasil",
              text:
                parseInt(statusCategory) === 1
                  ? "Kategori anda telah dinonaktifkan"
                  : "Kategori anda telah diaktifkan",
              icon: "success",
              showConfirmButton: true,
              confirmButtonText: "Tutup",
            }).then(() => this.listSellerCategory(sellerId));
          })
          .catch((error) => {
            Swal.disableLoading();
            if (error.response) {
              /*
               * The request was made and the server responded with a
               * status code that falls out of the range of 2xx
               */
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              if (error.response) {
                let errmessage = "";
                let keys = Object.keys(error.response.data.errors);
                for (let i = 0; i < keys.length; i++) {
                  errmessage +=
                    keys[i] +
                    " " +
                    error.response.data.errors[keys[i]][0] +
                    "<br/>";
                }
                Swal.fire({
                  icon: "error",
                  html: errmessage,
                });
              }
            } else if (error.request) {
              /*
               * The request was made but no response was received, `error.request`
               * is an instance of XMLHttpRequest in the browser and an instance
               * of http.ClientRequest in Node.js
               */
              console.log(error.request);
            } else {
              // Something happened in setting up the request and triggered an Error
              console.log("Error", error.message);
            }
            console.log(error.config);
          });
      } else {
        Swal.close();
      }
    });
  };

  render() {
    return (
      <SellerCategoryContext.Provider
        value={{
          ...this.state,
          changeHandler: this.changeHandler,
          checkDefaultIconHandler: this.checkDefaultIconHandler,
          modalHandler: this.modalHandler,
          selectDefaultIconHandler: this.selectDefaultIconHandler,
          selectIconCategoryHandler: this.selectIconCategoryHandler,
          getDefaultCategoryIcon: this.getDefaultCategoryIcon,
          listSellerCategoryActive: this.listSellerCategoryActive,
          listSellerCategory: this.listSellerCategory,
          storeSellerCategory: this.storeSellerCategory,
          updateSellerCategory: this.updateSellerCategory,
          beActiveCategory: this.beActiveCategory,
        }}
      >
        {this.props.children}
      </SellerCategoryContext.Provider>
    );
  }
}

export default SellerCategoryProvider;
