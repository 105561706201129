import React, { useContext } from "react";
import { Text } from "../../context/Language";
import {
  MDBNavLink,
  MDBNavItem,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdown,
  MDBDropdownMenu,
} from "mdbreact";
import SellerPrmContext from "../../context/SellerPrmContext";

export const HeaderMenuUser = (props) => {
  const sellerPrmContext = useContext(SellerPrmContext);
  return (
    <div>
      {props.logged ? (
        <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle nav caret color="secondary">
              <Text pid="userMenu_header" tid="helloText" />
              {sellerPrmContext.dataUser ? sellerPrmContext.dataUser.name : ""}
            </MDBDropdownToggle>
            <MDBDropdownMenu color="secondary">
              <MDBDropdownItem>
                <MDBNavLink className="black-text" to="/profile">
                  <Text pid="userMenu_header" tid="profileBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBNavLink
                  className="black-text"
                  to="#"
                  onClick={props.logout}
                >
                  <Text pid="userMenu_header" tid="logoutBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
      ) : (
        <MDBNavItem>
          <MDBNavLink
            className="black-text align-self-center col-example text-left"
            to="/login"
          >
            <Text pid="t1_header" tid="loginBtn" />
          </MDBNavLink>
        </MDBNavItem>
      )}
    </div>
  );
};
