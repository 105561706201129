import React, { useContext } from "react";
import { Text } from "../../context/Language";
import {
  MDBNavLink,
  MDBNavItem,
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBDropdown,
  MDBDropdownMenu,
} from "mdbreact";
import SellerPrmContext from "../../context/SellerPrmContext";

export const HeaderMenuSeller = () => {
  const sellerPrmContext = useContext(SellerPrmContext);

  if (sellerPrmContext.isSeller) {
    return (
      <div>
        <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle nav caret color="secondary">
              Seller Menu
            </MDBDropdownToggle>
            <MDBDropdownMenu color="secondary">
              <MDBDropdownItem>
                <MDBNavLink className="black-text" to="/admin/sellerprofile">
                  <Text pid="sellerMenu_header" tid="sellerProfileBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBNavLink className="black-text" to="/admin/storeprofile">
                  <Text pid="sellerMenu_header" tid="sellerAboutSettingBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBNavLink className="black-text" to="/admin/sellerlocation">
                  <Text pid="sellerMenu_header" tid="sellerLocationBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBNavLink className="black-text" to="/admin/sellercategory">
                  <Text pid="sellerMenu_header" tid="sellerCategoryBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
              <MDBDropdownItem>
                <MDBNavLink className="black-text" to="/admin/sellerproducts">
                  <Text pid="sellerMenu_header" tid="sellerProductBtn" />
                </MDBNavLink>
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem>
      </div>
    );
  } else {
    return <></>;
  }
};
