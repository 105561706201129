import React, { Component } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
} from "mdbreact";
import AsyncSelect from "react-select/async";
// import { Select } from "react-select-virtualized";
// import Select from "react-virtualized-select";
// import "react-select/dist/react-select.css";
// import "react-virtualized-select/styles.css";

class SellerLocationFormModal extends Component {
  render() {
    return (
      <MDBContainer>
        <MDBModal
          isOpen={this.props.modalState}
          toggle={() => this.props.toggleHandler("CLOSE")}
        >
          <MDBModalHeader>
            {this.props.actionType === "ADD" ? "Tambah Alamat" : "Ubah Alamat"}
          </MDBModalHeader>
          {/* <form
            onSubmit={
              this.props.actionType === "ADD"
                ? this.props.storeSellerLocation
                : this.props.updateSellerLocation
            }
          > */}
          <MDBModalBody>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.alias}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="alias"
                    label="Nama Tempat"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.hp}
                    onChange={this.props.changeHandler}
                    type="phone"
                    className="form-control"
                    name="hp"
                    label="Handphone"
                    required
                  />
                  <small>*Minimal 6 karakter</small>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.name}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="name"
                    label="Atas Nama"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    value={this.props.address}
                    onChange={this.props.changeHandler}
                    type="text"
                    className="form-control"
                    name="address"
                    label="Alamat"
                    required
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  {this.props.actionType === "ADD" ? (
                    <AsyncSelect
                      defaultInputValue="Ketik minimal 3 huruf untuk cari alamat"
                      loadOptions={this.props.loadOptions}
                      onChange={this.props.handleChangeSelect}
                      value={this.props.selectedAddress}
                      cacheOptions
                    />
                  ) : (
                    <AsyncSelect
                      loadOptions={this.props.loadOptions}
                      onChange={this.props.handleChangeSelect}
                      value={this.props.selectedAddress}
                      defaultValue={this.props.selectedAddressDefault}
                      cacheOptions
                    />
                  )}
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBInput
                    onChange={this.props.checkGudangHandler}
                    type="checkbox"
                    className="form-control"
                    name="isCheckGudang"
                    id="isCheckGudang"
                    label="Apakah menggunakan Check Gudang?"
                    checked={this.props.isCheckGudang}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter center>
            <MDBBtn
              color="success"
              onClick={
                this.props.actionType === "ADD"
                  ? () => this.props.storeSellerLocation(this.props.sellerId)
                  : () =>
                      this.props.updateSellerLocation(
                        this.props.sellerLocationId,
                        this.props.sellerId
                      )
              }
            >
              {this.props.actionType === "ADD" ? "Tambah" : "Ubah"}
            </MDBBtn>
            <MDBBtn
              onClick={() => this.props.toggleHandler("CLOSE")}
              outline
              color="success"
            >
              Batal
            </MDBBtn>
          </MDBModalFooter>
          {/* </form> */}
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default SellerLocationFormModal;
