import React, { Component } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBCard,
  MDBBtn,
  MDBSpinner,
  MDBSelect,
  MDBFileInput,
} from "mdbreact";
import { Text } from "../../context/Language";
import MDBFileUpload from "mdb-react-fileupload";

export default class ProfileViewSeller extends Component {
  render() {
    const props = this.props;
    const {
      isEdit,
      isEditChange,
      changeHandler,
      updateSellerProfile,
      checkDomainAvailability,
      selectTemplateHandler,
      // selectDetailProductPhoto,
      changeImageHandler,
      isDefaultHandler,
    } = props;
    return (
      <MDBCard>
        <form
          className="needs-validation"
          onSubmit={updateSellerProfile}
          noValidate
        >
          <MDBContainer style={{ minWidth: "900px" }}>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  value={props.nameWeb}
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  name="nameWeb"
                  label={<Text pid="registerPage" tid="storeNameInput" />}
                  disabled={isEdit}
                  required
                />
              </MDBCol>
            </MDBRow>

            {/* domain */}
            <MDBRow>
              <MDBCol size="4">
                <MDBInput
                  label={<Text pid="registerPage" tid="subDomainInput" />}
                  value={props.new_subDomain}
                  onChange={changeHandler}
                  onBlur={
                    props.new_subDomain === props.old_subDomain
                      ? ""
                      : () =>
                          checkDomainAvailability(props.new_subDomain, "sub")
                  }
                  disabled={isEdit}
                  name="new_subDomain"
                  required
                />
              </MDBCol>
              <MDBCol className="md-form form-control border-0" size="2">
                {".1toko.com "}
              </MDBCol>
              <MDBCol size="6">
                <MDBInput
                  value={props.new_domain}
                  onChange={changeHandler}
                  onBlur={
                    props.new_domain === props.old_domain
                      ? ""
                      : () =>
                          checkDomainAvailability(props.new_domain, "domain")
                  }
                  name="new_domain"
                  label={<Text pid="registerPage" tid="domainInput" />}
                  disabled={isEdit}
                />
              </MDBCol>
            </MDBRow>
            {/* domain validation */}
            <MDBRow>
              <MDBCol size="6">
                {this.props.renderLoaderSub ? (
                  <>
                    <MDBSpinner />
                    <Text pid="registerPage" tid="checkingDomain" />
                  </>
                ) : (
                  ""
                )}
                {!this.props.renderLoaderSub &&
                this.props.isAvailableSubDomain &&
                this.props.isSubDomainChecked ? (
                  <div style={{ color: "green" }}>
                    <Text pid="registerPage" tid="availableDomain" />
                  </div>
                ) : (
                  ""
                )}
                {!this.props.renderLoaderSub &&
                !this.props.isAvailableSubDomain &&
                this.props.isSubDomainChecked ? (
                  <div style={{ color: "red" }}>
                    <Text pid="registerPage" tid="unavailableDomain" />
                  </div>
                ) : (
                  ""
                )}
              </MDBCol>
              <MDBCol size="6">
                {this.props.renderLoaderDomain ? (
                  <>
                    <MDBSpinner />
                    <Text pid="registerPage" tid="checkingDomain" />
                  </>
                ) : (
                  ""
                )}
                {!this.props.renderLoaderDomain &&
                this.props.isAvailableDomain &&
                this.props.isDomainChecked ? (
                  <div style={{ color: "green" }}>
                    <Text pid="registerPage" tid="availableDomain" />
                  </div>
                ) : (
                  ""
                )}
                {!this.props.renderLoaderDomain &&
                !this.props.isAvailableDomain &&
                this.props.isDomainChecked ? (
                  <div style={{ color: "red" }}>
                    <Text pid="registerPage" tid="unavailableDomain" />
                  </div>
                ) : (
                  ""
                )}
              </MDBCol>
            </MDBRow>
            {/* sosmed */}
            <MDBRow>
              <MDBCol>
                <MDBInput
                  value={props.facebook}
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  name="facebook"
                  label="Facebook"
                  disabled={isEdit}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  value={props.twitter}
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  name="twitter"
                  label="Twitter"
                  disabled={isEdit}
                />
              </MDBCol>
              <MDBCol>
                <MDBInput
                  value={props.instagram}
                  onChange={changeHandler}
                  type="text"
                  className="form-control"
                  name="instagram"
                  label="Instagram"
                  disabled={isEdit}
                />
              </MDBCol>
            </MDBRow>

            {/* template */}
            <MDBRow>
              <MDBCol>
                <MDBSelect
                  search
                  options={props.template}
                  selected="Pilih tema anda"
                  label="Template"
                  getValue={selectTemplateHandler}
                  disabled={isEdit}
                  required
                />
              </MDBCol>
            </MDBRow>
            {/* logo dan banner */}
            <MDBRow>
              <MDBCol size="5">
                <MDBContainer>
                  <MDBRow style={{ paddingBottom: "5px" }}>
                    <MDBCol>{"CURRENT LOGO"}</MDBCol>
                    <MDBCol>
                      <MDBRow end>
                        <MDBInput
                          label="use default"
                          id="checkLogo"
                          type="checkbox"
                          onChange={() => isDefaultHandler("logo")}
                          checked={props.is_default_logo}
                          disabled={isEdit}
                        />
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol middle>
                      <img
                        className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                        src={
                          props.is_default_logo
                            ? props.default_selected_logo
                            : props.preview_logo
                        }
                        style={{ maxHeight: "150px" }}
                      />
                      <div hidden={isEdit || props.is_default_logo}>
                        <MDBFileInput
                          getValue={(e) => changeImageHandler(e, "logo")}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow style={{ paddingBottom: "5px" }}>
                    <MDBCol>{"CURRENT BANNER"}</MDBCol>
                    <MDBCol>
                      <MDBRow end>
                        <MDBInput
                          label="use default"
                          id="checkBanner"
                          type="checkbox"
                          onChange={() => isDefaultHandler("banner")}
                          checked={props.is_default_banner}
                          disabled={isEdit}
                        />
                      </MDBRow>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol middle>
                      <img
                        className=" img-fluid img-thumbnail z-depth-1 mx-auto d-block"
                        src={
                          props.is_default_banner
                            ? props.default_selected_banner
                            : props.preview_banner
                        }
                        style={{ maxHeight: "150px" }}
                      />
                      <div hidden={isEdit || props.is_default_banner}>
                        <MDBFileInput
                          getValue={(e) => changeImageHandler(e, "banner")}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBCol>
              <MDBCol size="7" middle>
                <MDBRow start>
                  <span style={{ paddingLeft: "1rem" }}>Template Preview</span>
                </MDBRow>
                <img
                  className="img-thumbnail z-depth-1"
                  src={props.template_preview}
                  style={{
                    minHeight: "400px",
                    maxHeight: "400px",
                    minWidth: "400px",
                  }}
                />
              </MDBCol>
            </MDBRow>
            <hr />
            {/* button */}
            <MDBRow>
              <MDBCol size="6" hidden={isEdit ? true : false}>
                <MDBBtn color="deep-purple" className="w-100" type="submit">
                  {<Text pid="profileUser" tid="submitBtn" />}
                </MDBBtn>
              </MDBCol>
              <MDBCol size="6" hidden={isEdit ? true : false}>
                <MDBBtn
                  color="deep-purple"
                  className="w-100"
                  onClick={() => isEditChange("cancel")}
                >
                  {<Text pid="profileUser" tid="cancelBtn" />}
                </MDBBtn>
              </MDBCol>
              <MDBCol hidden={isEdit ? false : true}>
                <MDBBtn
                  color="deep-purple"
                  className="w-100"
                  onClick={() => isEditChange("edit")}
                >
                  {<Text pid="profileUser" tid="editBtn" />}
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </form>
      </MDBCard>
    );
  }
}
