import React, { Component } from "react";
import MainRoutes from "../../MainRoutes";
import Header1 from "../../template1/Header";
import Routes1 from "../../template1/Routes";
import Header2 from "../../template2/Header";
import Routes2 from "../../template2/Routes";
// import Header3 from "../../template3/Header";
import Routes3 from "../../template3/Routes";
// import Header4 from "../../template4/Header";
import Routes4 from "../../template4/Routes";
import SellerPrmContext from "../../context/SellerPrmContext";
import { withRouter, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import MainHeader from "../MainHeader/MainHeader";
import API from "../../utils/API";
import Cookies from "js-cookie";

class TemplateSwitcher extends Component {
  static contextType = SellerPrmContext;
  constructor(props) {
    super(props);
    this.state = {
      headerHeight: 0,
    };
  }

  componentDidMount() {
    // this.context.checkUserDomain();
    // if (Cookies.get("access_token")) {
    //   this.context.checkDataUser();
    // }
  }

  checkHeaderHeight = (headerHeight) => {
    this.setState({ headerHeight: headerHeight });
  };

  logout = () => {
    this._isMounted = true;

    let token = Cookies.get("access_token");

    const config = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer " + token,
      },
    };
    API.get("/auth/logout", config)
      .then((res) => {
        Cookies.remove("access_token");
        Cookies.remove("qqq");
        this.props.history.push("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    if (
      this.context.subDomainData &&
      parseInt(this.context.subDomainData.template_id) === 1
    ) {
      return (
        <>
          <Header1
            subDomainData={this.context.subDomainData}
            isSeller={this.context.isSeller}
            logout={this.logout}
            checkHeaderHeight={this.checkHeaderHeight}
          />
          <div style={{ marginTop: this.state.headerHeight }}>
            <Routes1 />
          </div>
        </>
      );
    } else if (
      this.context.subDomainData &&
      parseInt(this.context.subDomainData.template_id) === 2
    ) {
      return (
        <>
          <Header2
            subDomainData={this.context.subDomainData}
            isSeller={this.context.isSeller}
            logout={this.logout}
            checkHeaderHeight={this.checkHeaderHeight}
          />
          <div style={{ marginTop: this.state.headerHeight }}>
            <Routes2 />
          </div>
        </>
      );
    } else if (
      this.context.subDomainData &&
      parseInt(this.context.subDomainData.template_id) === 3
    ) {
      return (
        <>
          <Header1
            subDomainData={this.context}
            isSeller={this.context.isSeller}
            logout={this.logout}
          />
          <div style={{ marginTop: this.state.headerHeight }}>
            <Routes3 />
          </div>
        </>
      );
    } else if (
      this.context.subDomainData &&
      parseInt(this.context.subDomainData.template_id) === 4
    ) {
      return (
        <>
          <Header1
            subDomainData={this.context}
            isSeller={this.context.isSeller}
            logout={this.logout}
          />
          <div style={{ marginTop: this.state.headerHeight }}>
            <Routes4 />
          </div>
        </>
      );
    } else {
      return (
        <>
          <MainHeader
            isSeller={this.context.isSeller}
            checkHeaderHeight={this.checkHeaderHeight}
          />
          <div style={{ marginTop: this.state.headerHeight }}>
            <MainRoutes />
          </div>
        </>
      );
    }
  }
}

export default withRouter(TemplateSwitcher);
