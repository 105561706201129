import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBContainer,
  MDBNavbarToggler,
  MDBIcon,
  MDBCol,
  MDBRow,
} from "mdbreact";
import Cookies from "js-cookie";

import { withRouter } from "react-router-dom";
import "./css/Header.css";
import { Text } from "../context/Language";
import HeaderProducts from "../components/HeaderAfterLogin/HeaderProducts";
import { HeaderMenuSeller } from "../components/HeaderAfterLogin/HeaderMenuSeller";
import { HeaderMenuUser } from "../components/HeaderAfterLogin/HeaderMenuUser";

class Header extends Component {
  state = {
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    const logoStyle = {
      minHeight: "2.5rem",
      minWidth: "2.5rem",
      maxHeight: "10rem",
      maxWidth: "10rem",
    };

    const logoContainerStyle = {
      position: "relative",
      display: "flex",
      alignItems: "center",
    };

    return (
      <MDBNavbar light color="white" expand="lg" fixed="top" scrolling>
        <MDBContainer>
          <MDBNavbarToggler onClick={this.toggleCollapse("navbarCollapse")} />
          <MDBCollapse
            id="navbarCollapse"
            isOpen={this.state.collapseID}
            navbar
          >
            <MDBNavbarNav left>
            <MDBNavItem>
                  <HeaderProducts />
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink className="black-text" to="/about">
                    <Text pid="t1_header" tid="aboutBtn" />
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink className="black-text" to="#!">
                    <Text pid="t1_header" tid="faqBtn" />
                  </MDBNavLink>
                </MDBNavItem>
                {this.props.isSeller ? (
                  " "
                ) : (
                  <div className="icon-cart">
                    <MDBNavItem>
                      <MDBNavLink
                        className="black-text align-self-center col-example text-left"
                        to="/cart"
                      >
                        <MDBIcon icon="shopping-cart" />
                        <Text pid="t1_header" tid="cartBtn" />
                      </MDBNavLink>
                    </MDBNavItem>
                  </div>
                )}
                {Cookies.get("access_token") ? (
                  <>
                    <HeaderMenuSeller />
                    <HeaderMenuUser logged={true} logout={this.props.logout} />
                  </>
                ) : (
                  <HeaderMenuUser logged={false} />
                )}
            </MDBNavbarNav>

            <MDBNavbarBrand to="#!">
              <MDBNavLink className="black-text" to="/">
                <MDBRow>
                  <MDBCol
                    style={{
                      fontFamily: "Alegreya",
                      fontWeight: "500",
                      fontSize: "30px",
                    }}
                  >
                    <div>{this.props.subDomainData.name_web}</div>
                  </MDBCol>
                  <MDBCol style={logoContainerStyle}>
                    <img
                      src={this.props.subDomainData.logo}
                      style={logoStyle}
                      className="rounded float-right"
                      alt="aligment"
                    />
                  </MDBCol>
                </MDBRow>
              </MDBNavLink>
            </MDBNavbarBrand>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    );
  }
}

export default withRouter(Header);
