import React, { Component } from "react";
import ProductCategoryTabView from "./ProductCategoryTabView";

class ProductHomeView extends Component {
  componentDidMount() {
    const sellerId = this.props.sellerprm.subDomainData.id;
    this.props.producthome.getProductAllCategory(sellerId);
  }
    
  render() {
    const p = this.props.producthome;
    return (
      <>
        {p.category === undefined ? (
          ""
        ) : (
          <>
            <ProductCategoryTabView
              {...this.props}
            />
          </>
        )}
      </>
    );
  }
}

export default ProductHomeView;
