import React, { Component } from "react";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
} from "mdbreact";
import { Text } from "../../context/Language";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { Link } from "react-router-dom";

export default class MainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseID: "",
    };
  }

  toggleCollapse = (collapseID) => () =>
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));

  render() {
    return (
      <div>
        <MDBNavbar
          light
          color="grey lighten-5"
          expand="md"
          fixed="top"
          scrolling
        >
          <MDBContainer>
            <Link to="/">
              <MDBNavbarBrand>
                {/* <img src={satuTokoLogo} className={styles.logo} alt="satu-toko" /> */}
                1toko
              </MDBNavbarBrand>
            </Link>

            <MDBNavbarToggler onClick={this.toggleCollapse("navbarCollapse")} />
            <MDBCollapse
              id="navbarCollapse"
              isOpen={this.state.collapseID}
              navbar
            >
              <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBNavLink to="/login">
                    <Text pid="landing" tid="loginBtn" />
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/register">
                    <Text pid="landing" tid="registerBtn" />
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <LanguageSelector />
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>
    );
  }
}
