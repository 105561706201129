import React, { Component } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBContainer,
  MDBRow,
  MDBBadge,
  MDBBtn,
  MDBCol,
} from "mdbreact";

export default class ShipmentUserLocationInfo extends Component {
  render() {
    const { selected_location, popupModal } = this.props;
    return (
      <MDBCard>
        <MDBCardBody>
          <MDBCardTitle>Alamat Pengirim</MDBCardTitle>
          <hr />
          <MDBContainer>
            <MDBRow>
              {`${selected_location.name} (${selected_location.alias}) `}
              {selected_location.is_primary === "1" ? (
                <span>
                  &nbsp;
                  <MDBBadge color="deep-purple" pill>
                    Primary
                  </MDBBadge>
                </span>
              ) : (
                ""
              )}
            </MDBRow>
            <MDBRow>{selected_location.hp}</MDBRow>
            <MDBRow>{selected_location.address}</MDBRow>
            <MDBRow>{selected_location.zip_id.label}</MDBRow>
          </MDBContainer>
          <hr />
          <MDBRow>
            <MDBCol>
              <MDBBtn
                color="deep-purple"
                onClick={()=>popupModal("SHOW","1")}
              >
                Ganti Alamat
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    );
  }
}
